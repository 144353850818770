import React, { Fragment, useState } from 'react';
import NamesAndContact from './NamesAndContact';
import DateOfBirth from './DateOfBirth';
import CardSerial from './CardSerial';
import TnCs from './TnCs';
import Confirm from './Confirm.js';
import Welcome from '../SignUp/Welcome';
import PrivacyPolicy from './PrivacyPolicy';
import Consent from '../SignUp/Consent';
import Signature from '../SignUp/Signature';
import AxiosClient from '../../Helpers/AxiosClient';

// This is the parent component for sign up

// Sign up steps as follows:
// step 1: go to sign up tab (signup)
// step 2: privacy policy (pp)
// step 3: terms and conditions (tncs)
// step 4: first name, last name, contact number (namesandcontact)
// step 5: date of birth and gender (dateofbirth)
// step 6: agree to receive marketing comms (consent)
// step 7: enter card serial and confirm details (cardserial)
// step 8: add your initials and last name as a signature (signature)
// step 9: you have been registered (confirm)

const signUpSteps = {
  signup: 1,
  privacypolicy: 2,
  tncs: 3,
  namesandcontact: 4,
  dateofbirth: 5,
  consent: 6,
  cardserial: 7,
  signature: 8,
  confirm: 9,
};

const SignUp = () => {
  const [step, updateStep] = useState(signUpSteps.signup);
  const [signUpDetails, setSignUpDetails] = useState({});

  // Terms and conditions and Consent
  const [isChecked, setIsChecked] = useState('not checked');

  // Name and Contact details
  const [cellNumber, setCellNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');

  // Date of Birth
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState(null);

  // Card Serial
  const [cardSerial, setCardSerial] = useState('');

  // Signature
  const [consentInitials, setConsentInitials] = useState('');
  const [consentSurname, setConsentSurname] = useState('');

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Errors
  const [validationErrors, setValidationErrors] = useState([]);

  const resetHooks = () => {
    setIsChecked('not checked');
    setCellNumber('');
    setFirstName('');
    setSurname('');
    setDateOfBirth(null);
    setGender(null);
  };

  // General validations
  const nameValidation = (char) => {
    var ex = new RegExp('^[a-zA-Z0-9-.,]+(s{0,1}[a-zA-Z0-9-., ])*$');
    if (ex.test(char)) return false;
    return true;
  };

  const phoneNumberValidation = (num) => {
    var ex = new RegExp('^[0-9]+$');
    if (ex.test(num)) return false;
    return true;
  };

  // Endpoints and token
  let endpoint = '';
  let token = '';

  // Beta
  if (window.location.href.includes('beta') || window.location.href.includes('localhost')) {
    endpoint = 'https://beta-funcs-shopper-dev-san.azurewebsites.net/api';
    token = '7ShRIkDXABxpqOjjC9GHzVYKWY0n-RkkAX8296dIpnjcAzFu3IrFBQ==';
  }

  // Staging
  if (window.location.href.includes('staging')) {
    endpoint =
      'https://funcs-shopper-staging-san.azurewebsites.net/api';
    token = 'k0Tvz0LeUGZEJcMN3K1JlqtEg3i2l8CHmJyKMHtTm7n6AzFujCBTYA==';
  }

  // Production
  if (window.location.href.includes('holaclub.co.za')) {
    endpoint = 'https://shopper-prod-san.azurewebsites.net/api';
    token = 'IEOnr8uB5XPFqX48YKl8wdcB4OqnDkj1YmDiMs7IOhp6AzFu9mGvxQ==';
  }

  if (!endpoint || !token) {
    throw new Error('Could not determine endpoint parameters.');
  }

  const funcsClient = new AxiosClient(endpoint, token);

  const handleErrorResponse = (error) => {
    setValidationErrors([error]);
  };

  const updateSignUpDetails = (infoToAdd, page, nextStep) => {
    let details = { ...signUpDetails, [page]: infoToAdd };

    let validateData = {
      firstName: firstName,
      surname: surname,
      dateOfBirth: dateOfBirth,
    };

    let duplicateCheck = {
      cellNumber: cellNumber,
      cardSerial: cardSerial,
    };

    let createData = {
      firstName: firstName,
      surname: surname,
      dateOfBirth: dateOfBirth,
      cellNumber: cellNumber,
      gender: gender,
      cardSerial: cardSerial,
      consentInitials: consentInitials,
      consentSurname: consentSurname,
    };

    const config = {
      validateStatus: (status) => {
        if (status === 400) return true;
        return status >= 200 && status < 300;
      },
    };

    // APIs in page step order:
    if (page === 'NamesAndContact') {
      setIsLoading(true);

      funcsClient
        .post('/ShopperDuplicateCheck', duplicateCheck, config)
        .then((res) => {
          if (res.data.cellNumberExists === 'false') {
            updateStep(nextStep);
            setSignUpDetails(details);
          } else {
            setValidationErrors((prevState) => [
              ...prevState,
              'This cell number already exists.',
            ]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          handleErrorResponse(error.toString());
          setIsLoading(false);
        });
    } else if (page === 'DateOfBirth') {
      setIsLoading(true);

      funcsClient
        .post('/ShopperValidate', validateData, config)
        .then((res) => {
          if (res.data.exists === false) {
            updateStep(nextStep);
            setSignUpDetails(details);
          } else {
            setValidationErrors((prevState) => [
              ...prevState,
              'This person already exists.',
            ]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          handleErrorResponse(error.toString());
          setIsLoading(false);
        });
    } else if (page === 'CardSerialNumber') {
      setIsLoading(true);

      funcsClient
        .post('/ShopperDuplicateCheck', duplicateCheck, config)
        .then((res) => {
          if (res.data.cardSerialExists === 'false') {
            updateStep(nextStep);
            setSignUpDetails(details);
          } else {
            setValidationErrors((prevState) => [
              ...prevState,
              'This card serial number already exists.',
            ]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          handleErrorResponse(error.toString());
          setIsLoading(false);
        });
    } else if (page === 'Signature') {
      setIsLoading(true);

      funcsClient
        .post('/ShopperCreate', createData, config)
        .then((res) => {
          if (res.data.resultCode === 0) {
            updateStep(nextStep);
            setSignUpDetails(details);
          } else {
            handleErrorResponse(res.data.resultMessage);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          handleErrorResponse(error.toString());
          setIsLoading(false);
        });
    } else {
      updateStep(nextStep);
      setSignUpDetails(details);
    }
  };

  return (
    <Fragment>
      <div className="flex flex-col w-full text-center self-center mx-auto min-h-screen 3xl:bg-gradient-to-b 3xl:from-lightgray 3xl:to-lightgreen md:bg-gradient-to-b md:from-white md:to-white">
        <div className="sm:w-3/4 3xl:w-1/4 xl:w-1/2 self-center mx-auto mt-32 bg-white 3xl:rounded-md 3xl:p-12 md:p-0 mb-4 overflow-auto 3xl:shadow-2xl md:shadow-none">
          {step === signUpSteps.signup && (
            <Welcome
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'SignUp',
                  signUpSteps.privacypolicy
                );
              }}
            />
          )}
          {step === signUpSteps.privacypolicy && (
            <PrivacyPolicy
              prevStep={() => {
                updateStep(signUpSteps.signup);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'PrivacyPolicy',
                  signUpSteps.tncs
                );
              }}
            />
          )}
          {step === signUpSteps.tncs && (
            <TnCs
              prevStep={() => {
                updateStep(signUpSteps.privacypolicy);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'TermsAndConditions',
                  signUpSteps.namesandcontact
                );
              }}
              onCheckedChange={setIsChecked}
              isChecked={isChecked}
            />
          )}
          {step === signUpSteps.namesandcontact && (
            <NamesAndContact
              prevStep={() => {
                updateStep(signUpSteps.tncs);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'NamesAndContact',
                  signUpSteps.dateofbirth
                );
              }}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              cellNumber={cellNumber}
              setCellNumber={setCellNumber}
              firstName={firstName}
              setFirstName={setFirstName}
              surname={surname}
              setSurname={setSurname}
              isLoading={isLoading}
              nameValidation={nameValidation}
              phoneNumberValidation={phoneNumberValidation}
            />
          )}
          {step === signUpSteps.dateofbirth && (
            <DateOfBirth
              prevStep={() => {
                updateStep(signUpSteps.namesandcontact);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'DateOfBirth',
                  signUpSteps.consent
                );
              }}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              setGender={setGender}
              gender={gender}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              isLoading={isLoading}
              returnToStart={() => {
                updateStep(signUpSteps.signup);
                setValidationErrors([]);
                setSignUpDetails({});
                resetHooks();
              }}
            />
          )}
          {step === signUpSteps.consent && (
            <Consent
              prevStep={() => {
                updateStep(signUpSteps.dateofbirth);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'Consent',
                  signUpSteps.cardserial
                );
              }}
              onCheckedChange={setIsChecked}
              isChecked={isChecked}
            />
          )}
          {step === signUpSteps.cardserial && (
            <CardSerial
              prevStep={() => {
                updateStep(signUpSteps.consent);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'CardSerialNumber',
                  signUpSteps.signature
                );
              }}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              cardSerial={cardSerial}
              setCardSerial={setCardSerial}
              cellNumber={cellNumber}
              firstName={firstName}
              surname={surname}
              dateOfBirth={dateOfBirth}
              gender={gender}
              isLoading={isLoading}
            />
          )}
          {step === signUpSteps.signature && (
            <Signature
              prevStep={() => {
                updateStep(signUpSteps.cardserial);
                setValidationErrors([]);
              }}
              nextStep={(infoToAdd) => {
                updateSignUpDetails(
                  infoToAdd,
                  'Signature',
                  signUpSteps.confirm
                );
              }}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              consentInitials={consentInitials}
              setConsentInitials={setConsentInitials}
              consentSurname={consentSurname}
              setConsentSurname={setConsentSurname}
              isLoading={isLoading}
              nameValidation={nameValidation}
            />
          )}
          {step === signUpSteps.confirm && <Confirm />}
        </div>
      </div>
    </Fragment>
  );
};

export default SignUp;
