import React, { Fragment } from 'react';
import Button from '../Common/Button';
import CustomerPrivacyPolicy from '../PrivacyPolicy/CustomerPrivacyPolicy';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const PrivacyPolicy = (props) => {
  const { nextStep, prevStep } = props;

  window.scrollTo(0, 0);

  return (
    <Fragment>
      <div className="flex flex-col mx-auto self-center">
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />
        <p className={steps}>Step 1 of 7</p>
        <div className="3xl:h-124 md:h-full overflow-auto 3xl:px-4 md:px-0">
          <CustomerPrivacyPolicy />
          <Button onClick={(e) => nextStep({})} content="NEXT" />
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
