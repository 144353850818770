import React, { useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import CustomerTerms from '../TermsAndConditions/CustomerTerms';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const TnCs = (props) => {
  const { nextStep, prevStep, isChecked, onCheckedChange } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center"
        onSubmit={(e) => {
          e.preventDefault();
          nextStep({
            isChecked: isChecked,
          });
        }}
      >
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />
        <p className={steps}>Step 2 of 7</p>
        <div className="3xl:h-124 md:h-full overflow-auto 3xl:px-4 md:px-0">
          <CustomerTerms />
          <div className="flex flex-row text-left mb-4">
            <input
              checked={isChecked === 'checked TNCs'}
              type="checkbox"
              className="mr-4 mt-1.4 relative"
              onChange={(e) => {
                onCheckedChange(
                  e.target.checked ? 'checked TNCs' : 'not checked'
                );
              }}
            />
            <div>I have read and agree to the terms and conditions</div>
          </div>
          <Button content="NEXT" disabled={isChecked !== 'checked TNCs'} />
        </div>
      </form>
    </Fragment>
  );
};

export default TnCs;
