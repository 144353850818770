import React from 'react';

const CashierPrivacyPolicy = () => {
  return (
    <div className="flex flex-col self-center mx-auto justify-center mt-12">
      <div className="self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12">
        <p>
          <h1 className="text-primary text-2xl font-bold text-center 3xl:pb-24 pt-24 md:pb-12">
            Cashier Privacy Policy
          </h1>
          <p className="text-base pt-1 pb-4">Effective Date: May 2021</p>
          <ol className="text-base pb-4 list-decimal">
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                What does this privacy policy cover?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              This privacy policy provides information about the processing of
              your personal information to which we have access, as part of your
              participation in the Hola Club Rewards Programme (the “Rewards
              Programme”) on behalf of your employer, i.e. the Outlet Owner. The
              Outlet Owner is a member of the Rewards Programme.
            </p>
            <p className="text-base pt-1 pb-4">
              Your personal information is collected the first time that you
              sign in and use our Hola Club device. This personal information is
              then stored on our database for the purposes listed below. Your
              personal information is required in order for you to participate
              in the Rewards Programme on behalf of your employer. Failure to
              supply this personal information will not enable you to
              participate in the Rewards Programme on behalf of your employer.
            </p>
            <p className="text-base pt-1 pb-4">
              This privacy policy explains what personal information we will
              process, for which purposes, how long we will hold your personal
              information, how you can access your personal information and
              where to go for further information.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">Children </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We do not intentionally collect data of those under 18 years old.
              If you are under that age, do not send us your personal
              information (for example your name, address and telephone number).
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Who is responsible for your personal information?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              Touchsides S.A. (Pty) Ltd (“we” or “us”) is responsible for your
              personal information.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                What personal information do we process?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We will collect and use the following information:
            </p>
            <p className="text-base pt-1 pb-4">
              <strong>Information about you</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>Full name; </li>
              <li>Surname;</li>
              <li>Gender; and </li>
              <li>Cell phone number</li>
            </ul>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Why do we process your personal information?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We process your personal information only if one of the following
              legal grounds exists:
            </p>
            <p className="text-base pt-1 pb-4">
              <strong>Contract</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                Where that is necessary to conclude or execute a contract that
                we have with you. For example, to be able to use our Hola Club
                device and to participate in the Rewards Programme on behalf of
                your employer.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>Legitimate interest</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                Where we have a legitimate business interest to do so. We will
                do so only within the boundaries of the data protection laws
                that apply to the processing of your personal information.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>Legal obligation</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                Where it is necessary to process your personal information to
                comply with a legal obligation to which we are subject.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>Consent</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                Or with your consent. We will always inform you and ask for your
                consent if we need to do so based on the data protection laws
                that apply to the processing of your personal information.
              </li>
            </ul>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                For what purposes do we process your personal information?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We process your personal information for one or more of the
              following purposes:
            </p>
            <p className="text-base pt-1 pb-4">
              <strong>
                To provide training on how to use our Hola Club Device
              </strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                We pull your personal information onto our database to associate
                a given training session with the correct outlet employee in
                order to ensure that you are trained on all modules.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>To provide an incentive </strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                You are rewarded with airtime for consumers that you are able to
                sign up on the Rewards Programme. We therefore require your
                personal information to ensure that we reward the correct
                person.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>Support services</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                Provide support to you in respect of the Rewards Programme and
                to process and respond to any questions or complaints you may
                have, or to update your personal information.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              <strong>Fraud detection and cybersecurity</strong>
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                To prevent and detect security threats, fraud, or other
                malicious activity.
              </li>
            </ul>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                How long do we keep your personal information?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We will keep your personal information as long as is necessary to
              fulfil the purposes that we describe in this policy.
            </p>
            <p className="text-base pt-1 pb-4">
              As a general rule we will keep your personal information, together
              with any updates, until a period of 18 months after your employer
              is no longer participates in the Rewards Programme.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Who will we share your personal information with?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We will share your personal information with providers who provide
              training on the use of the Hola Club Device (currently, Brands
              Unlimited Field Marketing Services (Pty) Ltd).
            </p>
            <p className="text-base pt-1 pb-4">
              We may share your information with our parent company and its
              affiliates, for the purposes outlined in this Policy.
            </p>
            <p className="text-base pt-1 pb-4">
              We may also share your information with:
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                law enforcement agencies, court, regulator, government authority
                or other third party where it is necessary to comply with a
                legal or regulatory obligation, or otherwise to protect our
                rights or the rights of any third party.
              </li>
            </ul>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Transfer of your personal information to other countries
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              Your personal information may be transferred to another country.
              For example, if your information is being hosted in a data centre
              outside South Africa, if we are able to remotely access your data
              from abroad or one of our IT suppliers provides off-premise
              maintenance and support services from outside South Africa.
              Countries to which we transfer personal information may have
              different privacy standards than the ones in South Africa. If we
              transfer your personal information to a country which does not
              offer an adequate level of protection we will ensure that we have
              put in place adequate safeguards to protect your personal
              information or otherwise ensure that we can transfer your
              information in a way that complies with data protection law.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Security of your personal information
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We will take appropriate, technical, physical, and organisational
              measures to protect your personal information from misuse or
              accidental, unlawful, or unauthorized destruction, loss,
              alteration, disclosure, acquisition, or access, that are
              consistent with applicable privacy practices and data security
              laws and regulations.
            </p>
            <p className="text-base pt-1 pb-4">
              Where we contract with any service providers, we require the
              service providers to use appropriate measures to protect the
              confidentiality and security of your personal information.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                What rights do you have?
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              You have several rights in relation to your personal information.
              For example: you can decide not to receive incentives from us,
              where we rely on your consent for the processing of your data you
              may withdraw your consent at any time and you can object to some
              of the ways we use your personal information.
            </p>
            <p className="text-base pt-1 pb-4">
              You can request us (using the details below) at any time:
            </p>
            <ul className=" list-disc text-base pt-1 pb-4">
              <li>
                To access your personal information (i.e. obtain an overview of
                your personal information we process).
              </li>
              <li>
                To have your personal information corrected, updated rectified
                or erased or to restrict the processing of your personal
                information.
              </li>
              <li>
                To receive a copy of your personal information in a commonly
                machine-readable format or to have this information transmitted
                directly to another organisation (if technically possible).
              </li>
              <li>
                To lodge a complaint with the Information Regulator of South
                Africa.
              </li>
            </ul>
            <p className="text-base pt-1 pb-4">
              To make sure that we do not provide information about you to
              someone else, we may ask for your identification before we can
              process your request.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                How to contact us
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              If you have any questions about how we treat your personal
              information or if you want to exercise your rights in relation to
              the personal information we process, please contact us at by email
              at info@holaclub.co.za or by calling +27 (10) 3125105 or (toll
              free) 0800 014809.
            </p>
            <li className="3xl:text-xl md:text-base font-bold">
              <h2 className="3xl:text-xl md:text-base font-bold">
                Changes to this policy
              </h2>
            </li>
            <p className="text-base pt-1 pb-4">
              We may amend this privacy policy within the limitations provided
              by the applicable laws such as the Protection of Personal
              Information Act, 2013 and consumer protection related legislation.
              We advise you to regularly visit our website (as indicated below)
              for possible changes to the privacy policy. Any material changes
              to the privacy policy will be included on the holaclub.co.za
              website.
            </p>
            <ul className=" text-base pt-1 pb-4">
              <p>
                <strong>Full Name:</strong> Touchsides S.A. (Proprietary)
                Limited
              </p>
              <p>
                <strong>Physical Address:</strong> The Oval West Wing, Wanderers
                Office Park, 52 Corlett Drive, Illovo, 2196
              </p>
              <p>
                <strong>Telephone No.:</strong> +27 (10) 312 5105
              </p>
              <p>
                <strong>Website Address:</strong> www.touchsides.com
              </p>
              <p>
                <strong>Email Address:</strong> info@touchsides.com
              </p>
              <p>
                <strong>Registration No.:</strong> 2007/007727/07
              </p>
              <p>
                <strong>Country of incorporation:</strong> South Africa
              </p>
            </ul>
          </ol>
        </p>
      </div>
    </div>
  );
};

export default CashierPrivacyPolicy;
