import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {
  return (
    <div className="w-full text-center mb-4">
      <CircularProgress size={30} />
    </div>
  );
};

export default Loading;
