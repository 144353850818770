import React from 'react';

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div>
      <div className="bg-secondary h-24 flex content-center text-sm text-center flex-wrap">
        <p className="mx-auto flex-1 text-white">
          Copyright © {getCurrentYear()}. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
