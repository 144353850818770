import React from 'react';

const Input = (props) => {
  const { placeholder, type, required, onChange, value } = props;
  return (
    <input
      className="border rounded-md border-primary p-2 mb-4 mt-2 w-full"
      placeholder={placeholder}
      type={type}
      required={required}
      onChange={onChange}
      value={value}
    />
  );
};

export default Input;
