import React from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const ErrorMessage = (props) => {
  const { content } = props;

  return (
    <div className="flex flex-row border-darkred bg-red border rounded-md px-4 py-4 mb-8">
      <ErrorOutlineOutlinedIcon
        className="w-8 self-center text-center mr-4 p-1 text-darkred"
        style={{ zoom: 1.5 }}
      />
      <div className="text-left text-sm text-darkred self-center">
        {content}
      </div>
    </div>
  );
};

export default ErrorMessage;
