import React, { useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import Input from '../Common/Input.js';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ErrorMessage from '../Common/ErrorMessage';
import useErrorHandler from '../Common/UseErrorHandler';
import Loading from '../Common/Loading';

const NamesAndContact = (props) => {
  const {
    nextStep,
    prevStep,
    validationErrors,
    setValidationErrors,
    firstName,
    setFirstName,
    surname,
    setSurname,
    cellNumber,
    setCellNumber,
    isLoading,
    nameValidation,
    phoneNumberValidation,
  } = props;

  const { hasError, setHasErrors } = useErrorHandler(validationErrors);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFields = () => {
    setValidationErrors([]);

    // First name validation
    if (firstName.length === 0) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a first name.',
      ]);
      return false;
    }
    if (firstName.length > 32) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a first name under 32 characters.',
      ]);
      return false;
    }
    if (nameValidation(firstName)) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Your first name cannot contain some special characters.',
      ]);
      return false;
    }

    // Last name validation
    if (surname.length === 0) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a surname.',
      ]);
      return false;
    }
    if (surname.length > 32) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a surname under 32 characters.',
      ]);
      return false;
    }
    if (nameValidation(surname)) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Your surname cannot contain some special characters.',
      ]);
      return false;
    }

    // Cell number validation
    if (cellNumber.length < 10 || cellNumber.length > 11) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a valid cell number with numbers only.',
      ]);
      return false;
    }
    if (phoneNumberValidation(cellNumber)) {
      setValidationErrors([]);
      setValidationErrors((prevState) => [
        ...prevState,
        'Cell number can contain numbers only.',
      ]);
      return false;
    }

    nextStep({
      firstName: firstName,
      surname: surname,
      cellNumber: cellNumber,
    });
    return true;
  };

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center "
        onSubmit={(e) => {
          e.preventDefault();
          validateFields();
        }}
      >
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />
        <p className={steps}>Step 3 of 7</p>
        <p className="text-left">* First Name:</p>
        <Input
          type="text"
          name="FirstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <p className="text-left" required>
          * Surname:
        </p>
        <Input
          type="text"
          name="Surname"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <p className="text-left">* Cell Number:</p>
        <Input
          onChange={(e) => setCellNumber(e.target.value)}
          value={cellNumber}
          name="CellNumber"
        />
        <label className="text-xs text-left mb-6">* required</label>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {hasError && <ErrorMessage error content={setHasErrors} />}
          </Fragment>
        )}
        <Button content="NEXT" type="submit" />
        <div className="flex flex-col"></div>
      </form>
    </Fragment>
  );
};

export default NamesAndContact;
