import React from 'react';

const Button = ({ content, onClick, disabled }) => {
  return (
    <button
      className={`bg-${
        disabled ? 'mediumgray' : 'primary'
      } text-white rounded-md border-primary p-4 mt-4 mb-12 ${
        content === 'NEXT' && 'w-full pr-4'
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default Button;
