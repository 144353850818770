import React, { useState, Fragment } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import hamburger from '../../Images/hamburger.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Menu = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [menuIsOpenTnCs, setMenuIsOpenTnCs] = useState();
  const [menuIsOpenPP, setMenuIsOpenPP] = useState();

  const handleAccordionTnCs = () => {
    setMenuIsOpenTnCs(!menuIsOpenTnCs);
  };
  const handleAccordionPP = () => {
    setMenuIsOpenPP(!menuIsOpenPP);
  };

  const handleSlide = () => {
    return `duration-500 h-full bg-gray w-40 z-20 fixed top-0 right-40 pt-16 mt-20 overflow-x-hidden transform 
    ${
      openMenu
        ? '-translate-x-full ease-in transition-medium'
        : '-translate-x-0 ease-out transition-medium'
    } `;
  };

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleMenu();
        }}
      >
        <img src={hamburger} alt="" className=" mt-3.4 md:h-6" />
      </button>
      {openMenu && (
        <Fragment>
          <button
            tabindex="-1"
            onClick={() => {
              handleMenu();
              setMenuIsOpenTnCs(false);
              setMenuIsOpenPP(false);
            }}
            className=" fixed inset-0 h-full w-full cursor-default bg-black opacity-75 duration-500 ease-in-out transform transition-medium mt-20 "
          />
        </Fragment>
      )}
      <div className={handleSlide()}>
        <div className="flex flex-col mb-24 mx-1 items-center">
          <NavLink
            className="border-darkgray border-solid border-b-2 w-full text-darkgray py-4 self-start pl-4 min-w-14"
            to="/"
            onClick={() => {
              handleMenu();
              setMenuIsOpenTnCs(false);
              setMenuIsOpenPP(false);
              window.scroll(0, 0);
            }}
          >
            HOME
          </NavLink>
          <NavLink
            to="/contact"
            className="border-darkgray border-solid border-b-2 w-full text-darkgray py-4 self-start pl-4 min-w-14"
            onClick={() => {
              handleMenu();
              setMenuIsOpenTnCs(false);
              setMenuIsOpenPP(false);
              window.scroll(0, 0);
            }}
          >
            CONTACT
          </NavLink>
          <div className="border-darkgray border-solid border-b-2 w-full text-darkgray py-4 self-start pl-4 min-w-14">
            <a
              href="https://sacoronavirus.co.za/"
              target="_blank"
              rel="noopener noreferrer"
            >
              COVID-19
            </a>
          </div>
          <NavLink
            className="hover:bg-gray-400  border-darkgray border-solid w-full border-b-2 text-darkgray py-4 self-start pl-4 min-w-14"
            to="/signup"
            onClick={() => handleMenu()}
          >
            SIGN UP
          </NavLink>
          <NavLink
            to="/contact"
            className="border-darkgray border-solid border-b-2 w-full text-darkgray pt-4 self-start pl-4 min-w-14"
            onClick={(e) => {
              e.preventDefault();
              handleAccordionTnCs();
            }}
          >
            <div className="flex flex-col">
              <label className="">TERMS & CONDITIONS</label>
            </div>
            {menuIsOpenTnCs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </NavLink>
          {menuIsOpenTnCs && (
            <div className="text-xs flex flex-col ">
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/termsandconditions/customerterms"
                onClick={() => {
                  handleAccordionTnCs();
                  setMenuIsOpenPP(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Customer Terms
              </NavLink>
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/termsandconditions/outletownerterms"
                onClick={() => {
                  handleAccordionTnCs();
                  setMenuIsOpenPP(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Outlet Owner Terms
              </NavLink>
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/termsandconditions/ewalletsolution"
                onClick={() => {
                  handleAccordionTnCs();
                  setMenuIsOpenPP(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Hola Club E Wallet Solution
              </NavLink>
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/termsandconditions/competition"
                onClick={() => {
                  handleAccordionTnCs();
                  setMenuIsOpenPP(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Competition
              </NavLink>
            </div>
          )}
          <NavLink
            to="/contact"
            className="border-darkgray border-solid border-b-2 w-full text-darkgray pt-4 self-start pl-4 min-w-14"
            onClick={(e) => {
              e.preventDefault();
              handleAccordionPP();
            }}
          >
            <div className="flex flex-col">
              <label className="">PRIVACY POLICY</label>
            </div>
            {menuIsOpenPP ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </NavLink>
          {menuIsOpenPP && (
            <div className="text-xs flex flex-col">
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/privacypolicy/customerprivacypolicy"
                onClick={() => {
                  handleAccordionPP();
                  setMenuIsOpenTnCs(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Customer Privacy Policy
              </NavLink>
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/privacypolicy/outletprivacypolicy"
                onClick={() => {
                  handleAccordionPP();
                  setMenuIsOpenTnCs(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Outlet Owner Privacy Policy
              </NavLink>
              <NavLink
                className="px-4 py-2 border-solid border-b border-lightgray"
                to="/privacypolicy/cashierprivacypolicy"
                onClick={() => {
                  handleAccordionPP();
                  setMenuIsOpenTnCs(false);
                  handleMenu();
                  window.scroll(0, 0);
                }}
              >
                Cashier Privacy Policy
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
