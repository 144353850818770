import React from 'react';

const OutletPrivacyPolicy = () => {
  return (
    <div className="flex flex-col self-center mx-auto justify-center mt-12">
      <div className="self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12">
        <p>
          <h1 className="text-primary text-2xl font-bold text-center 3xl:pb-24 pt-24 md:pb-12">
            Outlet Owner Privacy Policy
          </h1>

          <p className="text-base pt-1 pb-4">Effective Date: September 2020</p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            WHAT DOES THIS PRIVACY POLICY COVER?
          </h2>

          <p className="text-base pt-1 pb-4">
            This privacy policy provides information about the processing of
            your personal information when you participate in the Rewards
            Programme with your outlet and describes how we collect and use your
            personal information.
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            WHO IS RESPONSIBLE FOR YOUR PERSONAL INFORMATION?
          </h2>
          <p className="text-base pt-1 pb-4">
            Touchsides S.A. (Pty) Ltd (‘we’ or ‘us’) is responsible for your
            personal information.
          </p>
          <h2 className="3xl:text-xl md:text-base font-bold">
            WHAT PERSONAL INFORMATION DO WE PROCESS?
          </h2>
          <p className="text-base pt-1 pb-4">
            If you are a Rewards Programme member, we will collect, store and
            use the following information about your participation:
          </p>
          <p className="text-base pt-1 pb-4">
            <strong>Information about you</strong>
          </p>
          <ul className=" list-disc text-base pt-1 pb-4">
            <li>Full name and surname;</li>
            <li>Cell phone number;</li>
            <li>Email address;</li>
            <li>Gender;</li>
            <li>Date of birth;</li>
            <li>Rewards Programme customer card (‘Hola Club card’) number;</li>
            <li>Attendance at Rewards Programme events;</li>
            <li>
              The location of the outlet (address and GPS co-ordinates)- not
              used for tracking purposes;
              <li>All Hola Club device activity; and</li>
            </li>
            <li>
              Touchsides reserves the right to request your valid liquor license
              number.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Other</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Information related to your participation in Rewards Programme;
              and
            </li>
            <li className="my-1 pt-1 pb-4">
              Details of how you use our services
            </li>
          </ul>

          <h2 className="3xl:text-xl md:text-base font-bold">
            WHY DO WE PROCESS YOUR PERSONAL INFORMATION?
          </h2>
          <p className="text-base pt-1 pb-4">
            We process your personal information only if one of the following
            legal grounds exists:
          </p>
          <p className="text-base pt-1 pb-4">
            <strong>Contract</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Where that is necessary to conclude or execute a contract that we
              have with you. For example, to be able to register you as a member
              of the Rewards Programme.
            </li>
          </ul>
          <p className="text-base pt-1 pb-4">
            <strong>Legitimate interest</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Where we have a legitimate business interest to do so. We will do
              so only within the boundaries of the data protection laws that
              apply to the processing of your personal information. For example
              to help developing new products or services or to improve current
              products or services of ourselves or the participants in the
              Rewards Programme, to maintain or promote our relationship with
              you, to understand customer and shopping behaviour and trends or
              to personalise the marketing and promotional activities.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Legal obligation</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Where we believe it is necessary to process your personal
              information to comply with a legal obligation to which we are
              subject. For example, to prove that you are legally permitted to
              operate an outlet and/or purchase and sell alcoholic beverages.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Consent</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Or with your consent. We will always inform you and ask for your
              consent if we need to do so based on the data protection laws that
              apply to the processing of your personal information.
            </li>
          </ul>

          <h2 className="3xl:text-xl md:text-base font-bold">
            FOR WHAT PURPOSES DO WE PROCESS YOUR PERSONAL INFORMATION?
          </h2>
          <p className="text-base pt-1 pb-4">
            We process your personal information for one or more of the
            following purposes:
          </p>

          <p className="text-base pt-1 pb-4">
            <strong>To operate the Rewards Programme</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              For example, to create your user profile, or to provide other
              Rewards Programme related services (such as e-commerce services,
              and notifying you in case we need to change terms and conditions).
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>To compile profiles</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              We create a personal profile of you based on your registration
              information and your Hola Club device usage history. We can also
              use other information available to us (for example information you
              provided by a survey). We use this profile to understand and
              predict your sale-through and stock purchasing behaviour. We also
              create general group profiles (high level descriptions of
              different types of outlets) based on aggregated information.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>
              To send you personalised and general marketing communication
            </strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              One of the benefits of Rewards Programme is that we can show or
              send you adverts, offers or other marketing communication for
              products that we believe you might be interested in. We can do
              this in collaboration with participating partners. We also can
              send you information about new Rewards Programme services and
              other programmes and services offered by Touchsides.
            </li>
            <li className="my-1 pt-1 pb-4">
              We decide what communication, adverts or offers we show or send to
              you based on the information that you have provided to us as part
              of your participation in the Rewards Programme.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Monitor and analyse trends</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Based on your information we can monitor and analyse trends and
              assess the use and effectiveness of the Rewards Programme. For
              example, in relation to order behaviour and stock availability. We
              can also use the information to develop new products and services
              based on a better understanding of customers’ preferences and
              needs.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Participate in research activities</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              We can request you to participate research activities such as:
              surveys, pilots, panels, focus groups, and other research
              activities. Depending on the research activity, we will collect
              different sets of personal information. We will provide research
              activities either with your consent or because we have a
              legitimate interest, depending on the type and nature of the
              research activity.
            </li>
          </ul>
          <ul className="list-disc text-base pt-1 pb-4">
            Allow you to participate in sweepstakes, contests or other
            promotions
          </ul>
          <p>
            <strong>Support services</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              Provide support to you in respect of the Rewards Programme and to
              process and respond to any questions or complaints you may have,
              or to update your personal information.
            </li>
          </ul>

          <p className="text-base pt-1 pb-4">
            <strong>Fraud detection and cybersecurity</strong>
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              To prevent and detect security threats, fraud or other malicious
              activity
            </li>
          </ul>
          <h2 className="3xl:text-xl md:text-base font-bold">
            DIRECT MARKETING
          </h2>
          <p className="text-base pt-1 pb-4">
            We currently send you general and individualized direct marketing
            messages in accordance with the Consumer Protection Act, with an
            opportunity to opt out at any time. By becoming a member of the
            Rewards Programme, you consent to us using information about your
            location to show you adverts and offers for products we believe you
            might be interested in. In addition, by becoming a member of the
            Rewards Programme, you consent to us using your information for
            sending direct marketing messages to you by mail, e-mail, text
            messages, e-commerce platforms, social media platforms (including
            but not limited to WhatsApp Messenger), and other methods of
            (electronic) communications from time to time.
          </p>
          <p className="text-base pt-1 pb-4">
            Therefore, until the Protection of Personal Information Act, 4 of
            2013 (“POPI”) comes into effect, you consent to receiving such
            marketing communication and promotional content by signing up for
            the Rewards Programme. After POPI has come into effect, we will
            comply with the consent requirements in accordance with any specific
            consent requirements that may apply under POPI.
          </p>
          <p className="text-base pt-1 pb-4">
            You have the right to withdraw that consent at any time. For
            example: you can always opt out from receiving marketing
            communications and promotional content. In order to opt out or to
            withdraw your consent, you must contact your CSR. The process done
            by a CSR is a manual process and as such we will take between 2
            weeks to 4 weeks to process and finalise your opt out or withdrawal
            of consent.
          </p>
          <p className="text-base pt-1 pb-4">
            As the main purpose of your membership is to receive marketing and
            promotional content, if you withdraw consent or opt out of receiving
            marketing or promotional messages, your membership in the Rewards
            Programme will be terminated.
          </p>
          <h2 className="3xl:text-xl md:text-base font-bold">
            HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p className="text-base pt-1 pb-4">
            We will keep your information as long as is necessary to fulfil the
            purposes that we describe in this policy.
          </p>
          <p className="text-base pt-1 pb-4">
            As a general rule, we will keep, as allowed by applicable law, all
            information (other than personal information) regarding the outlet
            owner and outlet, including all transactions, stock management and
            any other information may be kept for an indefinite period, except
            for:
          </p>
          <p className="text-base pt-1 pb-4">
            We will keep the information that you provide when you register with
            the Rewards Programme, together with any updates, until a period of
            24 months after the termination of your membership (where membership
            is terminated for whatever reason) or after being deemed to be
            inactive as per the paragraph below, for operational purposes
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            WHO WILL WE SHARE YOUR INFORMATION WITH?
          </h2>
          <p className="text-base pt-1 pb-4">
            We are a member of the global HEINEKEN group of companies,
            information about the HEINEKEN group of companies can be found here:
            www.heinekencompany.com. We may share your information with our
            parent company if we require support services from the parent
            company such as information technology support/maintenance, legal
            support and to perform certain functions which we are not able to
            perform, without their support. We may share your information with
            our affiliates to ensure we have the same outlet name and physical
            address. If it would be sufficient to achieve the business purposes
            for which we are sharing data, we will only share data on an
            aggregated or de-identified level. We may also share your
            information with:
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              other organisations and service providers with whom we work or
              partner from time to time including, for example: third party
              websites, brand owners (companies participating in the Rewards
              Programme and their group companies), analytics providers and
              advertising and media companies to enable them to show you adverts
              or offers which may interest you, including online, brands and
              data companies: for purposes of marketing, promotional activities
              and data collection on outlet, customer and other demographics;
            </li>
            <li className="my-1 pt-1 pb-4">our professional advisors;</li>
            <li className="my-1 pt-1 pb-4">
              any law enforcement agency, court, regulator, government authority
              or other third party where we believe this is necessary to comply
              with a legal or regulatory obligation, or otherwise to protect our
              rights or the rights of any third party; and
            </li>
            <li className="my-1 pt-1 pb-4">
              any party that purchases, or to which we transfer, all or
              substantially all of our assets and business relating to the
              Rewards Programme. Should such a sale or transfer occur, we will
              use reasonable efforts to try to ensure that the entity to which
              we transfer your information uses it in a manner that is
              consistent with this policy.
            </li>
          </ul>

          <h2 className="3xl:text-xl md:text-base font-bold">
            TRANSFER OF YOUR PERSONAL INFORMATION TO OTHER COUNTRIES
          </h2>
          <p className="text-base pt-1 pb-4">
            Your personal information may be transferred to another country. For
            example, if your information is being hosted in a data centre
            outside South Africa, if we are able to remotely access your data
            from abroad or one of our IT suppliers provides off-premise
            maintenance and support services from outside South Africa.
            Countries to which we transfer personal information may have
            different privacy standards than the ones in South Africa. If we
            transfer your personal information to a country which does not offer
            an adequate level of protection we will ensure that we have put in
            place adequate safeguards to protect your personal information or
            otherwise ensure that we can transfer your information in a way that
            complies with data protection law.
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            SECURITY OF YOUR PERSONAL INFORMATION
          </h2>
          <p className="text-base pt-1 pb-4">
            We will take appropriate, technical, physical and organisational
            measures to protect your personal information collected through the
            Rewards Programme from misuse or accidental, unlawful or
            unauthorized destruction, loss, alteration, disclosure, acquisition
            or access, that are consistent with applicable privacy practices and
            data security laws and regulations.
          </p>
          <p className="text-base pt-1 pb-4">
            Where we contract with any service providers, we require the service
            providers to use appropriate measures to protect the confidentiality
            and security of your personal information.
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            WHAT RIGHTS DO YOU HAVE?
          </h2>
          <p className="text-base pt-1 pb-4">
            You have a number of rights in relation to your personal
            information. For example: you can decide not to receive marketing
            information from us, where we rely on your consent for the
            processing of your data you may withdraw your consent at any time
            and you can object to some of the ways we use your personal
            information. Note that some of the choices you make may impact or
            limit our ability to maintain and manage your membership with the
            Rewards Programme. Your membership may even have to be terminated.
            We will notify you if such action is necessary. You can request us
            (using the details below) at any time:
          </p>
          <ul className="list-disc text-base pt-1 pb-4">
            <li className="my-1 pt-1 pb-4">
              To access your personal information (i.e. obtain an overview of
              your personal information we process).
            </li>
            <li className="my-1 pt-1 pb-4">
              To have your personal information corrected, updated rectified or
              erased or to restrict the processing of your personal information.
            </li>
            <li className="my-1 pt-1 pb-4">
              To receive a copy of your personal information in a commonly
              machine-readable format or to have this information transmitted
              directly to another organisation (if technically possible).
            </li>
            <li className="my-1 pt-1 pb-4">
              To object to the processing of your personal information at any
              time, on reasonable grounds relating to your particular situation;
            </li>
            <li className="my-1 pt-1 pb-4">
              To object to the processing of your personal information, at any
              time, if the processing is for purposes of direct marketing by
              means of unsolicited electronic communications and you have not
              given your consent for that processing;
            </li>
            <li className="my-1 pt-1 pb-4">
              To lodge a complaint with the Information Regulator of South
              Africa via email at inforeg@justice.gov.za. Further details about
              the Information Regulator can be found at
              https://www.justice.gov.za/inforeg/.
            </li>
          </ul>
          <h2 className="3xl:text-xl md:text-base font-bold">
            HOW TO CONTACT US
          </h2>
          <p className="text-base pt-1 pb-4">
            If you have any questions about how we treat your personal
            information or if you want to exercise your rights in relation to
            the personal information we process, please contact your CSR or
            email us at info@holaclub.co.za, or call us at +27 (10) 312 5105.
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold">
            CHANGES TO THIS POLICY
          </h2>
          <p className="text-base pt-1 pb-4">
            We may amend this privacy policy within the limitations provided by
            the applicable laws such as POPI and consumer protection related
            legislation. We advise you to regularly visit our website (as
            indicated below) for possible changes to the privacy policy. Any
            changes to the privacy policy will be included on the holaclub.co.za
            website.
          </p>

          <h2 className="3xl:text-xl md:text-base font-bold pb-4">
            Touchsides S.A. Proprietary Limited information
          </h2>
          <ul className=" text-base pt-1 pb-4">
            <p>
              <strong>Full Name:</strong> Touchsides S.A. (Proprietary) Limited
            </p>
            <p>
              <strong>Physical Address:</strong> The Oval West Wing, Wanderers
              Office Park, 52 Corlett Drive, Illovo, 2196
            </p>
            <p>
              <strong>Telephone No.:</strong> +27 (10) 312 5105
            </p>
            <p>
              <strong>Website Address:</strong> www.touchsides.com
            </p>
            <p>
              <strong>Email Address:</strong> info@touchsides.com
            </p>
            <p>
              <strong>Registration No.:</strong> 2007/007727/07
            </p>
            <p>
              <strong>Country of incorporation:</strong> South Africa
            </p>
          </ul>
          <h2 className="3xl:text-xl md:text-base font-bold">
            CHANGE LOG AS AT JULY 2021
          </h2>
          <ul className="list-disc ml-4">
            <li className="my-1 pt-1">
              <b>Section 3: What personal information do we process</b>
              <li className="my-1 pt-1 list-none">
                We no longer process your email address, Identity number or
                Passport number.
              </li>
            </li>
            <li className="my-1 pt-1">
              <b>Section 6: Direct Marketing</b>
              <li className="my-1 pt-1 list-none">
                Updated to include direct marketing consent and the requirement
                there of as part of the Hola Club Rewards Programme.
              </li>
            </li>
            <li className="my-1 pt-1">
              <b>Section 7: How long do we keep your information?</b>
              <li className="my-1 pt-1 list-none">
                Please see update to timings.
              </li>
            </li>
            <li className="my-1 pt-1">
              <b>Section 8: Who will we share your information with?</b>
              <li className="my-1 pt-1 list-none">
                This has been updated to include the HEINEKEN Group of
                companies.
              </li>
            </li>
            <li className="my-1 pt-1 pb-4">
              <b>Section 11: What rights do you have?</b>
              <li className="my-1 pt-1 list-none">
                You may object the to the processing of your personal
                information. Please refer to section 11 for details.
              </li>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default OutletPrivacyPolicy;
