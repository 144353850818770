import React from 'react';
import green from '../Images/greenbackground.jpg';
import facebook from '../Images/facebook.png';
import phone from '../Images/phone.png';
import email from '../Images/email.png';

const Contact = () => {
  window.scrollTo(0, 0);

  return (
    <div className="flex flex-col w-full sm:mt-32 min-h-screen">
      <div className="flex flex-col mx-auto w-full absolute 3xl:mt-64 xl:mt-32 md:mt-24 sm:mt-4 ">
        <div className="text-center self-center 3xl:p-12 sm:p-1 bg-white sm:bg-transparent rounded-md">
          <p className="tracking-xlwide text-3xl text-primary font-bold self-center w-full">
            CONTACT
          </p>
          <p className="3xl:text-xl lg:text-base sm:text-sm text-darkgray pt-4">
            Operating Hours
          </p>
          <p className="3xl:text-xl lg:text-base sm:text-sm text-darkgray pt-1 pb-4">
            08h00 to 17h00 | Monday to Friday
          </p>
          <div className="text-left w-full 3xl:text-xl lg:text-base sm:text-sm">
            <div className="flex flex-row pt-2 self-center mx-20">
              <img src={facebook} alt="" className="h-6 w-6 " />
              <a
                className="pl-2"
                href="https://www.facebook.com/HolaClubSA/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @HolaClubSA
              </a>
            </div>
            <div className="flex flex-row pt-2 self-center mx-20">
              <img src={phone} alt="" className="h-6 w-6 " />
              <a className="pl-2" href="tel:[0103125105]">
                (010) 312 5105
              </a>
            </div>
            <div className="flex flex-row pt-2 self-center mx-20">
              <img src={email} alt="" className="h-6 w-6 3xl:mt-1 lg:mt-0" />
              <a className="pl-2" href="mailto:info@holaclub.co.za">
                info@holaclub.co.za
              </a>
            </div>
          </div>
        </div>
      </div>
      <img src={green} alt="" className="3xl:flex sm:hidden" loading="lazy" />
      <div className="3xl:hidden sm:flex w-full h-60 bg-gray" />
    </div>
  );
};

export default Contact;
