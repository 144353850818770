import React from 'react';
import welcome from '../Images/welcome.jpg';
import welcomemobile from '../Images/welcomemobile.jpg';
import buy from '../Images/buy.png';
import tap from '../Images/tap.png';
import win from '../Images/win.png';

const HomePage = () => {
  return (
    <div className="flex flex-col">
      <div className="w-full flex-1 absolute 3xl:mt-64 xl:mt-24 lg:-mt-4 md:mt-16 sm:mt-12 xs:mt-4 3xl:pl-24 md:pl-8">
        <p className="tracking-xlwide 3xl:text-3xl text-primary 3xl:w-1/3 sm:w-full font-bold pb-4 xl:text-base mt-20">
          <p>WELCOME TO</p>
          <p className="pt-2">HOLA CLUB™</p>
        </p>
        <p className="3xl:text-xl w-5/12 lg:w-1/3 md:w-1/2 sm:w-5/6 3xl:py-4 lg:py-2 xl:text-base">
          Hola Club is a <b>free</b> rewards programme that brings you deals and
          discounts on your favourite brands.
        </p>
        <p className="3xl:text-xl w-5/12 lg:w-1/3 md:w-1/2 sm:w-5/6 3xl:py-4 lg:py-2 xl:text-base">
          Stand a chance to <b>win</b> airtime and rewards every time you
          <b> buy</b> and <b>tap</b> your Hola Club card.
        </p>

        <p className="3xl:text-xl w-5/12 lg:w-1/3 md:w-1/2 sm:w-5/6 3xl:py-4 lg:py-2 xl:text-base">
          For more information, visit our{' '}
          <a
            href="https://www.facebook.com/HolaClubSA/"
            className="text-primary font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook page
          </a>
          .
        </p>
      </div>
      <img src={welcome} alt="" className="sm:hidden md:mt-20"></img>
      <img
        src={welcomemobile}
        alt=""
        className="3xl:hidden sm:flex w-full sm:mt-20"
      ></img>
      <div className="bg-gray h-24 text-center shadow-top flex flex-row self-center w-full">
        <div className="tracking-xlwide text-3xl xs:text-xl text-primary font-bold self-center w-full">
          HOW IT WORKS
        </div>
      </div>
      <div className="flex 3xl:flex-row sm:flex-col 3xl:w-3/5 self-center xl:w-5/6 md:w-full">
        <div className="text-center self-center flex-1 pb-12">
          <img
            src={buy}
            alt=""
            className="h-full w-full 3xl:pt-20 px-4 sm:pt-8"
          />
          <p className="text-secondary font-bold text-3xl pt-4 pb-2">BUY</p>
          <p className="3xl:text-lg lg:text-base">
            Make a purchase at any <br></br>participating
            <br className="3xl:hidden lg:flex sm:hidden"></br> taverns.
          </p>
        </div>
        <div className="text-center self-center flex-1 pb-12">
          <img
            src={tap}
            alt=""
            className="h-full w-full 3xl:pt-20 px-4  sm:pt-4"
          />
          <p className="text-secondary font-bold text-3xl pt-4 pb-2">TAP</p>
          <p className="3xl:text-lg lg:text-base">
            Tap your Hola Club™ card <br className="md:hidden sm:flex"></br>
            whenever you make a <br className="3xl:hidden lg:flex md:flex"></br>
            purchase.
          </p>
        </div>
        <div className="text-center self-center flex-1 pb-12">
          <img
            src={win}
            alt=""
            className="h-full w-full 3xl:pt-20 px-4 sm:pt-4"
          />
          <p className="text-secondary font-bold text-3xl pt-4 pb-2">WIN</p>
          <p className="3xl:text-lg lg:text-base">
            Earn rewards like airtime, <br></br>data, discounts
            <br className="3xl:hidden lg:flex sm:hidden"></br> and more!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
