import React, { Fragment } from 'react';
import SentimentSatisfiedRoundedIcon from '@material-ui/icons/SentimentSatisfiedRounded';

const Confirm = () => {
  window.scrollTo(0, 0);

  return (
    <Fragment>
      <div className="flex flex-col text-center self-center mt-20">
        <div className="flex flex-col border-primary border rounded-md px-4 py-2 mb-12">
          <SentimentSatisfiedRoundedIcon
            className="w-8 self-center text-center -mt-4 bg-lightgreen rounded-full text-primary "
            style={{ zoom: 4 }}
          />
          <div className="text-primary text-xl text-center font-bold md:my-12 3xl:my-32 3xl:px-8 md:px-0">
            Thanks for signing up to Hola Club!
          </div>
          <div className="text-primary text-lg text-center mb-8">
            Don't forget to tap your card to earn GREAT rewards!
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Confirm;
