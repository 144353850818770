import React from 'react';

const useErrorHandler = (errorList) => {
  return {
    hasError: errorList.length > 0,
    setHasErrors: (
      <div>
        <ul>
          {errorList.map((f, i) => (
            <li key={i}>{f}</li>
          ))}
        </ul>
      </div>
    ),
  };
};

export default useErrorHandler;
