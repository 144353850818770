import React, { useState, Fragment } from 'react';
import { headerButton } from './Utilities';
import logo from '../../Images/hclogo.png';
import facebookwhite from '../../Images/facebookwhite.png';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import Menu from './Menu';

const Header = () => {
  const [isOpenTnCs, setIsOpenTnCs] = useState();
  const [isOpenPP, setIsOpenPP] = useState();

  const handleDropdownTnCs = (val = true) => {
    setIsOpenTnCs(val);
    setIsOpenPP(false);
  };

  const handleDropdownPP = (val = true) => {
    setIsOpenPP(val);
    setIsOpenTnCs(false);
  };

  return (
    <Fragment>
      {isOpenTnCs && (
        <button
          tabindex="-1"
          onMouseOver={() => handleDropdownTnCs(false)}
          class="fixed inset-0 h-full w-full cursor-default z-40"
        />
      )}
      {isOpenPP && (
        <button
          tabindex="-1"
          onMouseOver={() => handleDropdownPP(false)}
          class="fixed inset-0 h-full w-full cursor-default z-40"
        />
      )}
      <div className="bg-primary flex flex-row shadow-header md:fixed md:w-full z-40 top-0 h-20">
        <div className="flex w-full flex-row z-50">
          <div className="flex-none text-center">
            <img src={logo} className="h-20 w-20 shadow-logo" alt="" />
          </div>
          <div className="flex-auto text-center mt-6">
            <NavLink className={headerButton} to="/">
              HOME
            </NavLink>
          </div>
          <div className="flex-auto text-center mt-6">
            <NavLink to="/contact" className={headerButton}>
              CONTACT
            </NavLink>
          </div>
          <div className="flex-auto text-center mt-6">
            <button className="text-lg text-white text-center self-center hover:text-darkgray md:hidden sm:hidden xs:hidden lg:text-xs xl:text-sm">
              <a
                href="https://sacoronavirus.co.za/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                COVID-19
              </a>
            </button>
          </div>
          <div className="flex-auto text-center mt-6">
            <NavLink className={headerButton} to="/signup">
              SIGN UP
            </NavLink>
          </div>
          <div className="flex-auto text-center mt-6">
            <button className="" onMouseOver={() => handleDropdownTnCs()}>
              <div className="text-lg text-white text-center self-center hover:text-darkgray md:hidden sm:hidden xs:hidden lg:text-xs xl:text-sm">
                TERMS & CONDITIONS
              </div>
            </button>
            {isOpenTnCs && (
              <div className="flex flex-none flex-col absolute bg-white mt-7.2 border border-primary text-primary w-1/5 text-base lg:text-xs 2xl:text-sm">
                <NavLink
                  className="hover:bg-primary hover:text-white py-4 "
                  to="/termsandconditions/customerterms"
                  onClick={() => handleDropdownTnCs(false)}
                >
                  CUSTOMER TERMS
                </NavLink>
                <NavLink
                  className="hover:bg-primary hover:text-white py-4 "
                  to="/termsandconditions/outletownerterms"
                  onClick={() => handleDropdownTnCs(false)}
                >
                  OUTLET OWNER TERMS
                </NavLink>
                <NavLink
                  className="hover:bg-primary hover:text-white py-4 "
                  to="/termsandconditions/ewalletsolution"
                  onClick={() => handleDropdownTnCs(false)}
                >
                  HOLA CLUB E WALLET SOLUTION
                </NavLink>
                <NavLink
                  className="hover:bg-primary hover:text-white py-4 px-1 "
                  to="/termsandconditions/competition"
                  onClick={() => handleDropdownTnCs(false)}
                >
                  COMPETITION
                </NavLink>
              </div>
            )}
          </div>
          <div className="flex-auto text-center mt-6">
            <button className="" onMouseOver={() => handleDropdownPP()}>
              <div className="text-lg text-white text-center self-center hover:text-darkgray md:hidden sm:hidden xs:hidden lg:text-xs xl:text-sm">
                PRIVACY POLICY
              </div>
            </button>
            {isOpenPP && (
              <div className="flex flex-auto flex-col absolute bg-white mt-7.2 border border-primary text-primary w-17/100 text-base lg:text-xs 2xl:text-sm">
                <NavLink
                  className="hover:bg-primary hover:text-white py-4"
                  to="/privacypolicy/customerprivacypolicy"
                  onClick={() => handleDropdownPP(false)}
                >
                  CUSTOMER PRIVACY POLICY
                </NavLink>
                <NavLink
                  className="hover:bg-primary hover:text-white py-4"
                  to="/privacypolicy/outletprivacypolicy"
                  onClick={() => handleDropdownPP(false)}
                >
                  OUTLET OWNER PRIVACY POLICY
                </NavLink>
                <NavLink
                  className="hover:bg-primary hover:text-white py-4"
                  to="/privacypolicy/cashierprivacypolicy"
                  onClick={() => handleDropdownPP(false)}
                >
                  CASHIER PRIVACY POLICY
                </NavLink>
              </div>
            )}
          </div>
          <div className="flex-none text-center ">
            <button className="mt-4 md:hidden">
              <a
                href="https://www.facebook.com/HolaClubSA/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebookwhite}
                  alt=""
                  className="h-12 w-12 flex justify-end mr-4"
                />
              </a>
            </button>
          </div>
          <div className="flex-auto flex-row flex justify-end mt-4 pr-6 3xl:hidden md:flex">
            <Menu />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
