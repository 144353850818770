import React, { useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';

const Consent = (props) => {
  const { nextStep, prevStep, isChecked, onCheckedChange } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center"
        onSubmit={(e) => {
          e.preventDefault();
          nextStep({
            isChecked: isChecked,
          });
        }}
      >
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />
        <p className={steps}>Step 5 of 7</p>
        <div className="border-primary border rounded-md mb-8 mt-6">
          <PlaylistAddCheckOutlinedIcon
            className="w-8 self-center text-center -mt-4 bg-lightgreen rounded-full p-1 border-primary border"
            style={{ zoom: 1.5 }}
          />
          <div className="flex flex-row text-center mb-2 ">
            <input
              checked={isChecked === 'checked Consent'}
              type="checkbox"
              className="ml-4 mt-5.4 text-center  "
              onChange={(e) => {
                onCheckedChange(
                  e.target.checked ? 'checked Consent' : 'not checked'
                );
              }}
            />
            <div className="text-left p-4 flex-1">
            I hereby give my consent to receive direct marketing of foodstuffs, non-food items, beverages, tobacco and value-added services to be marketed by means of SMS.
            </div>
          </div>
        </div>

        <Button
          type="submit"
          content="NEXT"
          disabled={isChecked !== 'checked Consent'}
        />
      </form>
    </Fragment>
  );
};

export default Consent;
