import React, { useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Input from '../Common/Input.js';
import ErrorMessage from '../Common/ErrorMessage';
import useErrorHandler from '../Common/UseErrorHandler';
import Loading from '../Common/Loading';

const Signature = (props) => {
  const {
    nextStep,
    prevStep,
    validationErrors,
    setValidationErrors,
    consentInitials,
    setConsentInitials,
    consentSurname,
    setConsentSurname,
    isLoading,
    nameValidation,
  } = props;

  const { hasError, setHasErrors } = useErrorHandler(validationErrors);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFields = () => {
    setValidationErrors([]);

    // Initials validation
    if (consentInitials.length === 0) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter your initials.',
      ]);
      return false;
    }
    if (consentInitials.length > 10) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter initials under 10 characters.',
      ]);
      return false;
    }
    if (nameValidation(consentInitials)) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Your initials cannot contain some special characters.',
      ]);
      return false;
    }

    // Last name validation
    if (consentSurname.length === 0) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter your surname.',
      ]);
      return false;
    }
    if (consentSurname.length > 32) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please enter a surname under 32 characters.',
      ]);
      return false;
    }
    if (nameValidation(consentSurname)) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Your surname cannot contain some special characters.',
      ]);
      return false;
    }

    nextStep({
      consentInitials: consentInitials,
      consentSurname: consentSurname,
    });
    return true;
  };

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center"
        onSubmit={(e) => {
          e.preventDefault();
          validateFields();
        }}
      >
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />
        <p className={steps}>Step 7 of 7</p>
        <p className="text-left mb-4">
          Please enter your initials and surname to confirm your signature:
        </p>
        <p className="text-left">* Initials:</p>
        <Input
          type="text"
          name="LastName"
          value={consentInitials}
          onChange={(e) => setConsentInitials(e.target.value)}
        />
        <p className="text-left">* Surname:</p>
        <Input
          type="text"
          name="Surname"
          value={consentSurname}
          onChange={(e) => setConsentSurname(e.target.value)}
        />
        <label className="text-xs text-left mb-4">* required</label>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {hasError && <ErrorMessage error content={setHasErrors} />}
          </Fragment>
        )}
        <Button type="submit" content="NEXT" />
      </form>
    </Fragment>
  );
};
export default Signature;
