import React, { Fragment } from 'react';
import signUp from '../../Images/signUp.png';
import Button from '../Common/Button.js';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';

const Welcome = (props) => {
  const { nextStep } = props;

  window.scrollTo(0, 0);

  return (
    <Fragment>
      <div className=" text-primary text-xl font-bold self-center mt-6">
        Welcome to Hola Club!
      </div>
      <div className="text-primary text-lg self-center mt-6 mb-4">
        Receive great rewards by signing up and using your Hola Club Card.
      </div>
      <div className="flex flex-col w-full ">
        <img src={signUp} alt="" className="w-28 self-center text-center" />
      </div>
      <div className="flex flex-col mx-auto self-center">
        <Button
          onClick={(e) => nextStep({})}
          className="bg-primary p-4 text-white mx-12"
          content="SIGN UP"
        />
      </div>
      <div className="flex flex-col border-primary border rounded-md px-4 py-2 mb-2 ">
        <WbIncandescentOutlinedIcon
          className="w-8 self-center text-center -mt-4  bg-lightgreen rounded-full p-1 border border-primary"
          style={{ zoom: 1.5, transform: 'rotate(180deg)' }}
        />

        <div className="text-left text-sm mt-4 mb-4">
          In order to sign up, you need to be 18 years or older and have your
          Hola Club card serial number on hand.
        </div>
      </div>
      <div className="text-sm self-center text-mediumgray mt-4">
        +2710 312 5100
      </div>
      <a
        href="mailto:info@holaclub.co.za"
        className="text-sm self-center text-primary underline"
      >
        info@touchsides.com
      </a>

      <div className="text-md self-center mt-4 text-mediumgray">
        Powered by Touchsides SA Pty Ltd.
      </div>
    </Fragment>
  );
};

export default Welcome;
