import axios from 'axios';
import { Service } from 'axios-middleware';

const service = new Service(axios);

service.register({
  onRequest(config) {
    //console.log('onRequest');
    return config;
  },
  onSync(promise) {
    //console.log('onSync');
    return promise;
  },
  onResponse(response) {
    //console.log('onResponse');
    return response;
  },
});

export default class AxiosClient {
  constructor(endpoint, token, timeout = 100000) {
    var newaxios = axios.create({
      baseURL: endpoint,
      timeout: timeout,
      headers: {
        Accept: 'application/json',
        'x-functions-key': token,
      },
    });

    newaxios.defaults.headers.common['Accept'] = 'application/json';
    newaxios.defaults.headers.post['Content-Type'] = 'application/json';

    return newaxios;
  }
}
