import React from "react";
const OutletOwnerTerms = () => {
  return (
    <div className="flex flex-col self-center mx-auto justify-center mt-12">
      <div className="self-center 3xl:w-1/2 mx-auto justify-center md:w-9/12">
        <h1 className="text-primary text-2xl font-bold 3xl:pb-24 pt-24 md:pb-12 text-center">
          Outlet Owner Terms and Conditions
        </h1>
        <ol className="custom-ol-style">
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              INTRODUCTION
            </span>
            <ol className="custom-ol-style text-base pb-4">
              <li className="my-1 pt-1 pb-4 pt-1 pb-4">
                The Hola Club Rewards Programme <b>(“Rewards Programme”)</b> is
                operated by and remains the sole property of Touchsides S.A.
                Proprietary Limited (<b>“Touchsides”</b>,<b> “we”</b>, or{" "}
                <b>“us”</b>), a limited liability private company incorporated
                under the laws of the Republic of South Africa, bearing
                registration number 2007/007727/07 with its registered address
                at The Oval West Wing Wanderers Office Park, 52 Corlett Drive,
                Illovo, Gauteng, 2196.
              </li>
              <li className="my-1 pt-1 pb-4">
                Ownership and all benefit of the Touchsides equipment, any other
                equipment, and the point of sales systems relating to the
                Touchsides equipment <b>(“Hola Club device(s)”)</b> provided to
                you as part of the Rewards Programme, will at all times be and
                remain vested in Touchsides notwithstanding their physical
                placement in your store, shop, tavern or other establishment (
                <b>“outlet/s”</b>, or
                <b>“You”</b>).
              </li>
              <li className="my-1 pt-1 pb-4">
                As part of these Terms and Conditions of the Rewards
                Programme (including the Privacy Policy), and by signing the
                Hola Club device agreement (<b>“the Agreement”</b>) and Equipment
                Receipt Form (<b>“ERF”</b>), and the installation acknowledgment form
                (<b>“IAF”</b>), (collectively the <b>“Terms”</b>), Touchsides reserves the
                right to issue, decline and/or withdraw the Rewards Programme or
                your membership thereof at any time without prior notice.
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms apply whether the Rewards Programme is branded under
                the ‘Hola Club’ brand, or otherwise. If not branded under the
                ‘Hola Club’ brand, then reference in these Terms to ‘Hola Club’
                refers to the brand utilised for the Rewards Programme in your
                outlet.
              </li>
              <li className="my-1 pt-1 pb-4">
                In order to participate in the Rewards Programme, and by your
                acceptance hereof, you agree to be bound by these Terms. Should
                you not agree to be bound by these Terms you shall not be
                entitled to membership in the Rewards Programme.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to amend these Terms. Any changes
                made to these Terms and Conditions in the future will be posted
                on our website and you may be provided with a notification. By
                continuing to participate in the Rewards Programme, you agree to
                be bound to the amended Terms. The Terms and Conditions will be
                available at www.holaclub.co.za. You are advised to review the
                website at regular intervals in order to ensure that you are
                satisfied with the Terms and Conditions. In the event that you
                disagree with any of the Terms, you must immediately contact
                your Rewards Programme customer service representative (<b>“CSR”</b>)
                and provide notice of termination in terms of clause 1.8.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should any of the provisions of the Terms or any part of the
                provisions of the Terms be found at any time by any court of
                competent jurisdiction to be illegal, invalid and/or
                unenforceable for any reason whatsoever, that provision or part
                of a provision will be severed, to the extent required, from the
                rest of the Terms so that the remaining terms remain unaffected
                thereby.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Terms constitutes a binding legal agreement between
                Touchsides and you and governs your use of the Hola Club
                devices. Before the passing of 5 (five) business days after
                signing the ERF, you may immediately terminate the Terms by
                notice to your CSR. After the passing of 5 business days after
                signing the ERF, you may terminate the Terms on 14 (fourteen)
                days’ notice to your CSR.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Terms may be immediately terminated by Touchsides, in
                writing, at any time and without Touchsides incurring any
                penalty and without Touchsides being liable for any loss or
                damage you may suffer if you perform a material breach of any
                provision of the Terms. In all other cases, we may terminate the
                Terms on 14 (fourteen) days’ notice for any reason without
                Touchsides incurring any penalty and without Touchsides being
                liable for any loss or damage you may suffer.
              </li>
              <li className="my-1 pt-1 p">
                For the avoidance of doubt, a termination of the Terms shall
                result in termination of your membership in the Rewards
                Programme and a termination of your membership in the Rewards
                Programme shall result in the termination of the Terms.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              WARRANTIES
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                You hereby warrant to and in favour of Touchsides that:
              </li>
              <ol className="custom-ol-style">
                <li className="my-1 pt-1 mx-2 pb-4">
                  you have the legal capacity and have taken all necessary
                  corporate action required to empower and authorise you to
                  conclude the Terms; and
                </li>
                <li className="my-1 pt-1 mx-2 pb-4">
                  the Terms constitute an agreement valid and binding on you and
                  enforceable against you in accordance with their terms.
                </li>
              </ol>
            </ol>
            <li className="my-1 pt-1 pb-4">
              The effect of clause 2.1 is that you acknowledge that which is set
              out in the clause.
            </li>
          </li>

          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              INSTALLATION
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Touchsides shall install the Hola Club devices in your outlet.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides is running three Rental Programs for the Hola Club
                device. The first being a Pay-for-Performance model for existing
                outlets signed up before 1 May 2023; the second being a fixed
                Debit Order rental model for new outlets signed up after 1 May
                2023, and the third being a phased roll-out converting existing
                outlets on the Pay-for-Performance model to a fixed term Debit
                Order model.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to convert existing Outlets signed
                up before the 1 May 2023 and on the Pay-for-Performance Rental
                model through the Kazang Wallet (“Kazang Wallet”) to a fixed
                Rental Debit Order model which is currently applicable to newly
                commissioned outlets.
              </li>
            </ol>

          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              EXISTING OUTLET PAY-FOR-PERFORMANCE RENTAL PROGRAM
            </span>
            <ol className="custom-ol-style m">
              <li className="my-1 pt-1 pb-4">
                I understand that the Outlet is not liable to pay for the
                Device/s or the installation thereof, provided that the Outlet
                meets the two performance criteria set out below.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand and agree that, if the Outlet meets the two
                performance criteria as set out in these terms and conditions,
                the monthly rental fee of R300.00 (three hundred rand)
                (including VAT) (“Rental Fee”) will not be payable. However, if
                the Outlet meets one but not the other performance criteria, it
                will pay half of the Rental Fee. And if both the performance
                criteria are not meet then the Outlet will pay the full Rental
                Fee.
              </li>
              <li className="my-1 pt-1 pb-4">
                In addition to the two performance criteria, the Outlet must
                ensure that it has a minimum balance of R600.00 (Six hundred
                rand) in the Outlet’s Kazang Wallet. This is equivalent to a 2
                month Rental Fee that is security in the event that the Outlet
                does not meet the performance criteria.
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand that the minimum requirement for free use and
                installation of the Device/s are that:
                <ol className="custom-ol-style my-a">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    the Outlet must generate no less than R20 000.00 (Twenty
                    thousand rand) captured on the Device per month; and
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    the Outlet must conduct 4 stock takes in a month on the
                    Device.
                  </li>
                  </ol></li>
                  <li className="my-1 pt-1  pb-4">
                    I accept that for failure to meet any one of the minimum
                    requirements of usage, an amount of R150 (one hundred and
                    fifty rands) rental will be levied per month against the
                    Outlet’s Kazang Wallet.
                  </li>
                  <li className="my-1 pt-1 pb-4">
                    I understand that no more than R300, Rental Fee will be
                    levied per month for the failure to meet both minimum
                    requirements.
                  </li>
                  <li className="my-1 pt-1  pb-4">
                    I authorise Touchsides to deduct any rental that is due and
                    payable from the Outlet’s Kazang Wallet by Touchsides SA
                    (Pty) Ltd.
                  </li>
                  <li className="my-1 pt-1 pb-4 ">
                    I understand and agree that Touchsides may remove the
                    Devices/s if the Outlet is unable to meet the criteria for 2
                    consecutive months.
                  </li>
            </ol>
          </li>

          <li>
            <span className="3xl:text-xl md:text-base ml-1 my-6 font-bold">
              EXISTING OUTLETS TO BE CONVERTED TO A DEBIT ORDER RENTAL MANDATE
            </span>

            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                {" "}
                I understand that existing Outlets on the Pay-for-Performance
                rental model will be signed up on a monthly Rental Fee of R300
                (three hundred rands) (including VAT) in a phased roll-out from
                1 June 2023.
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand that the collection of the Rental Fee for the use
                of the Hola Club device shall be done by way of a Debit Order.
                Each outlet owner shall enter into a Debit Order mandate (“Debit
                Order Mandate”).{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand that the Outlet owner must ensure that it has
                sufficient funds in their bank account on the day of the debit
                Order going through as per the Debit Order Mandate to cover the
                monthly subscription.
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand and agree that Touchsides reserves the right to
                remove the Hola Club device/s if the Outlet is unable to meet
                the criteria for 2 consecutive months, regardless of the fixed
                term Rental due, these are that:
                <ol className="custom-ol-style my-1">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    R20 000.00 (Twenty thousand rand) must be captured on the
                    Hola Club device per month; and
                  </li>
                  <li className="my-1 pt-1 mx-2">
                    the Outlet must conduct 4 stock takes in a month on the Hola
                    Club device.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                I authorise Touchsides to deduct no more that R300 that is due
                and payable from the Outlet as per the Debit Order Mandate.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              NEW OUTLETS DEBIT ORDER RENTAL MANDATE
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                I understand that Outlets commissioned from 1 May 2023 shall pay
                a monthly Rental Fee of R500 (five hundred rands) (including
                VAT)
              </li>

              <li className="my-1 pt-1 pb-4">
                {" "}
                I understand that the collection of the Rental Fee for the use
                of the Hola Club device shall be done by way of a Debit Order.
                Each outlet owner shall enter into a Debit Order mandate (“Debit
                Order Mandate”).{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                {" "}
                I understand that the Outlet owner must ensure that it has
                sufficient funds in their bank account on the day of the debit
                order going through as per the Debit Order Mandate to cover the
                monthly subscription.{" "}
              </li>
              <li className="my-1 pt-1 pb-4">
                I understand and agree that Touchsides reserves the right to
                remove the Hola Club device/s if the Outlet is unable to meet
                the criteria for 2 consecutive months, regardless of the fixed
                term Rental due, these are that:
                <ol className="custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    R20 000.00 (Twenty thousand rand) must be captured on the
                    Hola Club device per month; and
                  </li>
                  <li className="my-1 pt-1 mx-2">
                    the Outlet must conduct 4 stock takes in a month on the Hola
                    Club device.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                I authorise Touchsides to deduct no more that R500 that is due
                and payable from the Outlet as per the Debit Order Mandate.
              </li>
              </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides shall not be liable for any damage to the outlet as
                result of the installation of the Hola Club devices. The effect
                of this clause is the reduction of the liability of Touchsides
                for any damage caused by Touchsides in installing the Hola Club
                devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides will provide:
                <ol className="custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    training (within its sole and absolute discretion) to the
                    staff members at the Outlet for administration of the
                    Rewards Programme and /or the operation of the Hola Club
                    devices; and
                  </li>
                  <li className="my-1 pt-1  mx-2">
                    ongoing technical support and maintenance services of the
                    Hola Club devices to the Outlet, as we deem necessary and
                    within our sole and absolute discretion.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                You must, at your own cost and expense, at all times provide
                suitable and adequate electrical power supply and internet
                connectivity as may be required for the proper functioning of
                the Hola Club device(s).
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club devices are provided to you as is. The effect of
                this clause is that you shall have no claim against Touchsides
                for any defects in the Hola Club devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                All service and maintenance costs of the Hola Club devices will
                be covered by us provided that the service and maintenance
                required is due to fair wear and tear and not due to any
                negligence by you any of your individual partners, directors,
                officers, employees, representatives, agents, independent
                contractors, subcontractors, suppliers, licensors, or product
                providers, or service providers, (collectively “Personnel”), to
                the exclusion of Touchsides. Based on our assessment of the Hola
                Club devices, we reserve the right to decide whether the Hola
                Club devices will need to be repaired or replaced.
              </li>
              <li className="my-1 pt-1 pb-4">
                You shall take all steps necessary to prevent damage to or loss
                of the Hola Club devices. You further undertake not to cause the
                loss of or damage to the Hola Club devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                In the event of a breakdown in relation to the Hola Club
                devices, you will immediately advise us, where after we will
                arrange for the necessary repairs or replacement to be affected.
                We reserve the right to determine whether the Hola Club devices
                will need to be repaired or replaced or terminate your
                membership in the Rewards Programme and decommission the outlet.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should the damage to the Hola Club devices or loss thereof arise
                as a result of your acts, omissions, or negligence and/or the
                acts, omissions, or negligence of your Personnel, we reserve the
                right to immediately terminate these Terms and charge you for
                any costs we may incur in repairing or replacing the Hola Club
                devices including replacement and repairs falling outside acts
                of negligence. You and your Personnel agree to exercise due care
                and skill when handling the Hola Club devices. The effect of
                this clause is the imposition of risk on you for any damage or
                loss to the Hola Club device.
              </li>
              <li className="my-1 pt-1 pb-4">
                In the event of the Hola Club devices being stolen or damaged by
                any person, you shall immediately report the theft, where
                applicable, to the South African Police Service and notify us
                should any of the events above take place. We reserve the right
                to immediately terminate these Terms and charge you for any
                costs we may incur in repairing or replacing the Hola Club
                devices, regardless of the cause of the damage or loss thereto.
              </li>
              <li className="my-1 pt-1 pb-4">
                The effect of clauses 3.6 and 3.7 is the imposition of risk on
                you for any damage or loss to the Hola Club devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                You waive any right which you may have against us which arises
                directly or indirectly from any failure of the Hola Club devices
                or from the failure of us or any third party to maintain, repair
                or replace the Hola Club devices or for any loss or damage
                suffered by you under any circumstances whatsoever and howsoever
                arising, save where such loss or damage arises from our gross
                negligence. The effect of this clause is that you shall have no
                claim against us or any third party for a failure to maintain,
                repair, or replace the Hola Club devices or for any loss or
                damages you may suffer expect where such loss or damage is
                caused by our gross negligence.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should you be sequestrated or liquidated (provisionally or
                finally), we will have the right to cancel these Terms and to
                remove the Hola Club devices from your premises, without
                prejudice to any claim for damages which we may have suffered.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                In the event of the Terms being cancelled, for whatever reason,
                you shall immediately make available for collection the Hola
                Club devices by us and you agree to pay all costs incurred by us
                to secure recovery of the Hola Club devices or damages sustained
                as a result of the loss thereof, including any associated legal
                costs. The effect of this clause is the assumption of liability
                for any cost or damages we may incur in recovering the Hola Club
                devices on cancellation of the Terms.
              </li>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              THE HOLA CLUB CARD
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Touchsides may, from time to time, provide you with Rewards
                Programme customer loyalty cards (“Hola Club Card(s)”), whether
                branded under the ‘Hola Club’ brand, or otherwise.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club Cards, unless paid for by you, remain the property
                of Touchsides. You are required to keep the Hola Club Cards in a
                safe place.
              </li>
              <li className="my-1 pt-1 pb-4">
                You shall register all of your customers on the Rewards
                Programme and issue them with a Hola Club Card on the customer
                terms and conditions to be provided to you by Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club Card is to be used for purposes of a registered
                customer earning the advertised and promoted Rewards (as defined
                in clause 5.4), provided that all purchased products are scanned
                by the Hola Club device and the Hola Club Card is tapped against
                the Hola Club device prior to the registered customer paying for
                the products and leaving the outlet with the purchased products.
              </li>
              <li className="my-1 pt-1 pb-4">
                You may only issue the Hola Club Card in accordance with the
                Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club Card is not transferable.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club Card is limited to one card per registered
                customer and may only be used by the registered customer to whom
                it has been issued. No secondary cards may be issued to family
                members of the registered customer or other third parties.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club Card shall not be used as a credit, debit or
                guarantee card, save with the consent of Touchsides, which
                consent shall be at Touchsides’ sole discretion.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                In the event of a registered customer’s Hola Club Card being
                lost, damaged, or destroyed, you may issue the registered
                customer with a new Hola Club Card, provided that immediately
                thereafter you shall inform Touchsides that the registered
                customer’s Hola Club Card has been lost, damaged, or destroyed.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              SCAN YOUR PURCHASE AND SALES, AND TAP YOUR HOLA CLUB CARD AND
              FAILURE TO USE THE HOLA CLUB DEVICE/CARD
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Every product purchased from your outlet is required to be
                scanned through a Hola Club device and a customer’s Hola Club
                Card should be tapped against said Hola Club device.
              </li>
              <li className="my-1 pt-1 pb-4">
                Products that leave the outlet without being scanned and/or the
                Hola Club Card not being tapped against a Hola Club device, may
                not be later returned for scanning.
              </li>
              <li className="my-1 pt-1 pb-4">
                Products may only be scanned at the same outlet at which they
                were purchased.
              </li>
              <li className="my-1 pt-1 pb-4">
                You shall also be issued with Hola Club Cards for use by you and
                your applicable Personnel. It is your responsibility to ensure
                that these Hola Club Cards are only utilised by appropriate
                Personnel and are kept safe and secure.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are required to have all products purchased by your outlet,
                scanned at a Hola Club device on the date of delivery and to tap
                your Hola Club Card on said Hola Club device.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Touchsides may make available certain Value-Added Services
                (“VAS”) such as, though not limited to, prepaid airtime and
                insurance products that will be sold through the Hola Club
                device.
              </li>
              <li className="my-1 pt-1 pb-4">
                If you fail to use the Hola Club device daily, or if you fail to
                meet the minimum use requirements as determined at the sole
                discretion of Touchsides from time to time, then Touchsides, in
                its sole discretion, has the right to withdraw your membership
                in the Rewards Programme and remove the Hola Club devices and
                any unused Hola Club Cards from the outlet.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              REWARDS AND PROMOTIONS
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Purchases of specified promotional products, which are to be
                selected by Touchsides or other entities, will earn you the
                corresponding Rewards Programme rewards (“Rewards”) within the
                rules of the Rewards Programme. The quantity, nature and value
                of any Rewards will vary between the promotional products and
                will be at the sole discretion of Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                You become a member of the Rewards Programme and become eligible
                to earn Rewards by agreeing to the Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                Each purchase of a specified promotional product may earn the
                Reward advertised, in accordance with the rules of that
                particular promotion. Touchsides reserves the right to amend
                both the list of promotional products and their associated
                Rewards from time to time.
              </li>
              <li className="my-1 pt-1 pb-4">
                Rewards will be communicated by the Rewards Programme from time
                to time at Touchsides’ sole discretion.
              </li>
              <li className="my-1 pt-1 pb-4">
                Where free stock or free merchandise rewards are available to
                shoppers or customers, you are required to scan the free stock
                and free merchandise on the Hola Club device when the shopper
                makes a purchase that gives them access to the promotion.
              </li>
              <li className="my-1 pt-1 pb-4">
                Rewards older than 3 months or beyond validity, whichever is
                earlier, may become invalid. Failure to pass on the Rewards to
                the customer may result in Rewards not being added to the
                outlet’s account. The validity periods of Rewards are subject to
                change from time to time at the sole discretion of Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Reward points have no monetary value and are not redeemable or
                exchangeable for cash, unless specifically provided for by
                Touchsides, and cannot be used for the payment of credit.
              </li>
              <li className="my-1 pt-1 pb-4">
                Rewards only apply to products on promotion. Rewards cannot be
                backdated.
              </li>
              <li className="my-1 pt-1 pb-4">
                You agree that Rewards will be settled in a manner determined
                solely by Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Rewards are paid for by brands or Touchsides. You waive any
                right to claim compensation of any nature relating to the
                Rewards (including damages, expenses, costs or any other losses,
                including claims arising out of any fraudulent activity by any
                of your Personnel) from Touchsides, Touchsides’ Personnel and
                Touchsides’ shareholders. The effect of this clause is the
                limitation of Touchsides’ liability for any claims relating to
                the Rewards.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Where a promotion offers an instant price discount to a shopper
                or customer, your reward will be the difference between the
                recommended retail selling price and the promotion price. You
                will only redeem the reimbursement at the end of the promotion
                and after there has been verification of stock sold. If there
                has been any fraudulent activity by you or your personnel, you
                will be disqualified from the promotion and you will not qualify
                to redeem the reward.
                <ol className="custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Each price discount promotion would have its own specific
                    terms and conditions as provided by a brand owner in order
                    to be able to claim your reward (s). The terms and
                    conditions will include the duration of the promotion (s),
                    reward (s) and entry mechanisms.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Each price discount promotion will be added to the Hola Club
                    device, 4 weeks before the launch of the power promotion.
                    You will be able to see all the terms and conditions that
                    apply to that promotion. If you do not wish to participate
                    in a specific power promotion, you must select “OPT OUT”
                    from the Hola Club device screen.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4 last">
                You may not redeem any Rewards for products that are not on
                promotion.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                You may not issue Rewards to any customer if the promotion has
                expired.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                You may not try and sell, barter or exchange Rewards.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Every product purchased from your outlet is required to be
                scanned through a Hola Club device, in order for any Rewards to
                be allocated and redeemed.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                If there has been any fraudulent activity by you or your
                personnel, you will be disqualified from a promotion and you
                will not qualify to redeem a reward.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                There are different types of promotions that we run: (i) Hola
                Club promotions which are member only promotions. A shopper’s
                card must be tapped in order to receive rewards from Hola Club
                promotions; (ii) General promotions that are open to the general
                public (i.e. promotions are not limited to Hola Club
                customers/shoppers). A card tap is not required to receive a
                reward.
                <p className="mt-4">
                  <i>Rules applicable only to Hola Club promotions </i>
                </p>
              </li>
              <li className="my- pt-1 pb-4 last">
                A shopper’s/customer’s card must be tapped.{" "}
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Where airtime is offered as a reward, such airtime rewards can
                only be redeemed by members with a pre-paid or top-up account,
                and only on the following networks: MTN, Cell C, Telkom and
                Vodacom. These networks and criteria are subject to change from
                time to time at Touchsides’ sole discretion. Touchsides accepts
                no responsibility whatsoever for any Reward which is not
                received by any Rewards Programme Member if the cellphone number
                linked to the Rewards Programme does not meet the above
                criteria. You accordingly waive any right to claim compensation
                under these circumstances.
                <p className="mt-4">
                  <i>Rules applicable only to General promotions</i>
                </p>
              </li>
              <li className="my-1 pt-1 pb-4 last">
                A shopper’s/customer’s card does not need to be tapped in order
                for you to qualify for a reward.{" "}
              </li>
              <li className="my-1 pt-1 pb-4 last">
                You get cash rewards in accordance with the specific terms and
                conditions applicable to a promotion. Your cash reward will be
                given to you via eWallet or VAS wallet.{" "}
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              MEMBERSHIP AND DURATION
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Your qualification to be a member and continued participation in
                the Rewards Programme, will be determined by Touchsides in its
                sole and absolute discretion.
              </li>
              <li className="my-1 pt-1 pb-4">
                Your application or membership may therefore be rejected and/or
                terminated at any time by Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right not to disclose reasons in this
                regard.
              </li>
              <li className="my-1 pt-1 pb-4">
                These Terms shall commence immediately upon the date of
                signature of the ERF or the installation of any Hola Club device
                at the outlet, whichever is the earlier. The Terms will be
                binding on you and shall continue indefinitely, until terminated
                as provided for in the Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Rewards Programme will operate indefinitely and will
                continue until you are notified to the contrary.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides reserves the right to withdraw or cancel your
                membership in the Rewards Programme should you breach any of the
                Terms at any time; and at the sole discretion of Touchsides for
                any reason.
              </li>
              <li className="my-1 pt-1 pb-4">
                If you fail to use the Hola Club device daily, or if you fail to
                meet the minimum use requirements as determined at the sole
                discretion of Touchsides from time to time, then Touchsides, in
                its sole discretion, has the right to withdraw your membership
                in the Rewards Programme and remove the Hola Club devices and
                any unused Hola Club Cards from the outlet.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should the Rewards Programme cease to operate, all Rewards not
                redeemed by the date of termination of the Rewards Programme
                will be forfeited. Neither you nor the affected registered
                customers, will have a claim against Touchsides in respect
                thereof.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides may discontinue the Rewards Programme or any service
                offering, or may change the nature, features, functions, scope,
                or operation of the Rewards Programme or any service offering,
                at any time and from time to time at its sole discretion.
              </li>
              <li className="my-1 pt-1 pb-4">
                In the event of any fraud, abuse, misuse or misconduct by you,
                or your employees, whether directly or indirectly, involving the
                Hola Club Cards and/or related to the Rewards Programme
                including, but not limited to, conduct involving the false
                accumulation of points or redemption of Rewards, Touchsides, in
                its sole discretion, has the right to institute appropriate
                legal steps against you and all accrued Rewards will be
                forfeited. In addition, your Rewards Programme membership may be
                revoked by Touchsides. Touchsides reserves the right to message
                registered customers of the Rewards Programme informing them of
                suspension or revocation of the outlet’s membership. Such
                suspension or revocation of the outlet’s membership can be on a
                temporary or permanent basis, at the sole discretion of
                Touchsides, and in instances where Touchsides deems such
                suspension or revocation to be appropriate, at its sole
                discretion. Registered customers are not restricted to utilise
                their Hola Club Card at a specific outlet. Consequently, you
                shall have no claim against Touchsides for any loss of business
                and/or any other loss in this regard.
              </li>
              <li className="my-1 pt-1 pb-4">
                {" "}
                Upon termination of your membership in the Rewards Programme:
                <ol className="custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    Touchsides will attend to the outlet and remove all Hola
                    Club devices, marketing material and other documentation and
                    information in whatever form captured or stored relating to
                    the Rewards Programme and including any unused Hola Club
                    Cards and Hola Club Cards issued to you for use by you and
                    your applicable Personnel.
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    you shall, immediately upon termination of your membership:
                    <ol className="custom-ol-style">
                      <li className="my-1 pt-1 mx-2 pb-4">
                        cease to promote, market or advertise the Rewards
                        Programme in any manner whatsoever and cease to make use
                        of any of the intellectual property, approved marks,
                        information, documentation, devices, marketing material
                        and other documentation or information belonging to
                        Touchsides or relating to the Rewards Programme, in your
                        possession and control, and will bear any and all costs
                        associated with doing so;
                      </li>
                      </ol>
                      </li>
                      <li className="my-1 pt-1 pb-4">
                        cease to issue any Hola Club Cards;
                      </li>

                      <li className="my-1 pt-1 pb-4">
                        not allow any Hola Club Cards to be used for any other
                        purpose whatsoever in the outlet;
                      </li>
                      <li className="my-1 pt-1 pb-4">
                        take all reasonable steps to notify your registered
                        customers:{" "}
                        <ol className="custom-ol-style">
                          <li className="my-1 py-1 mx-3">
                            {" "}
                            of the termination of the Rewards Programme in the
                            outlet;
                          </li>
                          <li className="my-1 py-1 mx-3">
                            that the customers remain a part of the Rewards
                            Programme;
                          </li>
                          <li className="my-1 py-1 mx-3">
                            that the rewards earned by the customers remain
                            valid until such time as they are terminated in
                            terms of the customer terms and conditions; and
                          </li>
                          <li className="my-1 py-1 mx-3">
                            the customers may continue to make use of their Hola
                            Club Cards at other outlets,
                          </li>
                        </ol>
                      </li>
                      <li className="my-1 pt-1 mx-2">
                        permit the collection by Touchsides of all unused and/or
                        uncollected Hola Club Cards.
                      </li>
                  
                      </ol>
          </li>
                  <li className="my-1 pt-1 pb-4">
                    To the extent permitted under the Consumer Protection Act,
                    2008 (“CPA”), you indemnify and hold harmless Touchsides,
                    its Personnel and its shareholders, against all and any
                    claims which customers may have against you or any damages
                    or loss you may suffer following a termination of the
                    Rewards Programme in your outlet. The effect of this clause
                    is that you will be responsible and you shall not be able
                    sue Touchsides, its Personnel or its shareholders for any
                    claims customers may have relating to the termination of the
                    Rewards Programme in your outlet, or any damages or loss you
                    may suffer relating to the termination of the Rewards
                    Programme in your outlet.
                  </li>
                </ol>
              </li>
    
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              DATA AND PRIVACY
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                All data collected by Touchsides during the term of the Rewards
                Programme will, at all times, be and remain the sole and
                exclusive property of Touchsides. You provide Touchsides with
                unfettered and unencumbered use of this data.
              </li>
              <li className="my-1 pt-1 pb-4">
                Your employees shall also be bound by the obligations to protect
                the intellectual property right and all data on the Touchsides
                devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                You may not use any data, or reports, made available by
                Touchsides during the term of the Rewards Programme, other than
                internally, and you acknowledge that such data, or reports, may
                not be shared by you with any third party whatsoever.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are required to ensure that that information and all data
                will only be disclosed to your employees for the purpose of
                allowing them to execute their obligations towards you or
                Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are required to respect the right to privacy of our Hola
                Club Card holders and treat their personal information as
                confidential. You are not allowed to use personal information of
                Hola Club members for your own purposes or access their data
                without any clear business need to do so and/or instruction from
                us. If you become aware of any breach of personal information of
                Hola Club Card holders, you must report such to us promptly
                after becoming aware of it.
              </li>
              <li className="my-1 pt-1 pb-4">
                The obligation to protect and not disclose any data to third
                parties remains effective during the term of your agreement with
                Touchsides continues for a further five (5) years after the
                termination of rights and obligations in terms of your agreement
                with Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                We may collect and use your own personal information for the
                purposes as set out in the Hola Club Outlet Owner Privacy
                Policy.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              INTELLECTUAL PROPERTY RIGHTS
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Intellectual Property includes all current and future
                intellectual property rights of any kind whatsoever and however
                embodied which may subsist or be capable of protection
                wheresoever in the world, including (without limitation)
                patents, trademarks, present and future rights of copyright,
                rights in and to designs, rights in and to inventions,
                topography rights, rights in and to trade secrets, rights in and
                to: trade names, business names, domain names and logos, the
                right to keep information confidential and private, rights in
                and to know how, rights in and to databases (including rights of
                extraction), software, and all rights and forms of protection of
                a similar nature or having equivalent effect to any of them
                which may subsist or be capable of protection, wheresoever in
                the world, whether or not any of these is registered and
                including applications for any such rights or registration
                thereof and any goodwill related to or arising from such rights
                (“Touchsides IP”).
              </li>
              <li className="my-1 pt-1 pb-4">
                All Intellectual Property residing in the Rewards Programme is
                the exclusive property of Touchsides and the rights thereto,
                including the rights in and to the compilation of data stored on
                the Rewards Programme shall remain vested in Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides is entitled to take all and any steps necessary,
                including the institution of legal proceedings, to protect any
                Intellectual Property residing in or emanating from the Rewards
                Programme, including any Intellectual Property non-exclusively
                licensed to Touchsides by a third party, without notice to or
                permission from you.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides owns all rights, title and interest in and to the
                Hola Club devices and any data collected, originated and
                provided by and through its systems, including the applications
                by customers. For avoidance of doubt, customer data shall be
                deemed confidential and the proprietary information of
                Touchsides. As far as the law allows and except to the extent
                expressly permitted under the Terms, you shall not:
                <ol className="my-1 custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    attempt to copy, modify, duplicate, create derivative works
                    from, frame, mirror, republish, download, display, transmit,
                    or distribute all or any portion of the Touchsides IP in any
                    form or media or by any means;
                  </li>
                  <li className="my-1 mx-2 pt-1 pb-4">
                    attempt to de-compile, reverse compile, disassemble, or
                    reverse engineer Touchsides IP;
                  </li>
                  <li className="my-1 mx-2 pt-1 pb-4">
                    access all or any part of Touchsides IP in order to build a
                    product or service which competes with Touchsides; or
                  </li>
                  <li className="my-1 mx-2 pt-1">
                    use all or any part of the Hola Club devices to provide
                    services to third parties, other than your customers.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4 last">
                This clause will continue to be binding on Touchsides and you
                despite any termination or cancellation of the Terms.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              OTHER OUTLET RESPONSIBILITIES
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                You are required at all times to uphold the good name and
                reputation of the ‘Hola Club’ brand standards and representation
                within your outlet, and not to do or cause to be done anything
                that can negatively affect the ‘Hola Club’ brand, Rewards
                Programme or the reputation of Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should you have any suspicion of fraudulent activities or
                transactions on the part of the registered customers, your
                Personnel, in relation to the Rewards Programme, inform
                Touchsides immediately. All notices and processes arising out of
                these Terms should be provided to your CSR.
              </li>
              <li className="my-1 pt-1 pb-4">
                Faulty scanners or system issues must be reported within 24
                hours. Faulty Hola Club devices or systems, for whatever reason,
                will render, at the discretion of Touchsides, void any Rewards
                or potential Rewards on offer.
              </li>
              <li className="my-1 pt-1 pb-4">
                The Hola Club devices, the Hola Club Cards, are owned by
                Touchsides and remain the property of Touchsides. You will use
                the Hola Club devices, Hola Club Cards, and any related
                applications solely for your internal business purposes, and
                will not, for you, or any affiliate of you or any third party
                sell, rent, lease, license or sublicense, assign, distribute, or
                transfer; modify, change, alter, create derivative works of,
                decipher, decompile, disassemble, reconstruct, translate,
                reverse engineer, or discover any source code of the underlying
                ideas, algorithms, file formats, programming, or
                interoperability interfaces; copy any tangible versions of the
                systems or applications; or remove from any of the Hola Club
                devices any language or designation indicating the confidential
                nature thereof or the proprietary rights.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are required to use your best endeavours at all times to
                ensure the effective implementation and roll out of the Rewards
                Programme within the outlet including but not limited to
                ensuring that:
                <ol className="my-1 custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    staff training is allowed for appropriate staff;
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    the Hola Club devices in your outlet are in good working
                    order and condition at all times;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    the Hola Club devices are plugged in and maintained in good
                    working order and condition at all times;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    you will compensate Touchsides accordingly for any
                    intentional and unintentional, wilful and unwilful, or
                    negligent damage or loss to the Hola Club devices and agree
                    to replace all damaged Hola Club devices at your own cost;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    you must keep all display material relating to the Rewards
                    Programme in good condition and visible and available to
                    customers at all times;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    only devices that are supplied by us may be used in
                    conjunction with the Rewards Programme. If the Hola Club
                    device is modified, it is grounds for the immediate
                    cancellation of the Terms, the termination of your
                    membership in the Rewards Programme, as well our levying of
                    a reasonable cancellation fee on you as well as any fee
                    necessary to repair or reinstate the Hola Club device to its
                    / their original condition;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    the outlet raises the awareness of promotions through the
                    correct placement of promotional markers on shelf edges,
                    advertisements in outlet leaflets and the like;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    the outlet always has sufficient stock of Hola Club Cards to
                    meet the demands of customers;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    any user manuals, guides and training material provided by
                    Touchsides is kept in a good condition and in a place
                    accessible to all appropriate staff members at all
                    reasonable times;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    you are required to supply any and all such information and
                    documents required by Touchsides for the purposes of running
                    the Rewards Programme. Further, you must expeditiously
                    furnish Touchsides with the product details of all barcodes
                    on the outlet’s existing systems, where applicable, which
                    Touchsides may require at any time;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    if you are not the owner of the premises at the outlet where
                    the Hola Club device is to be installed, you must, prior to
                    any installation by us, obtain permission from the owner of
                    the premises for the installation and acknowledgement that
                    the Hola Club device belongs to Touchsides. You warrant that
                    you will do so before the installation date and indemnify
                    us, to the extent permitted under the CPA against damages or
                    claims resulting from the failure to obtain such permission
                    including all and any costs which may have to be incurred by
                    us (the effect of this sentence is that you promise you will
                    have the permission from the owner before the installation
                    of the Hola club devices and if you do not, you will be
                    responsible for loss, damage, or costs we suffer as a result
                    of your failure);
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    {" "}
                    where applicable, ensure that wholesale customers are issued
                    with a Hola Club Card specifically designated as being for
                    wholesale customers by Touchsides;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    as and when required, the outlet agrees to do a full
                    integration of its existing point of sale system with the
                    Rewards Programme at no cost to Touchsides;
                  </li>

                  <li className="my-1 pt-1 mx-2 pb-4">
                    ensure that the outlet staff are familiar with their various
                    obligations arising out of the Terms and discipline staff
                    members appropriately who are found not to be complying with
                    such obligations; you and your staff members actively
                    encourage customers to register and to utilise the Rewards
                    Programme as often as possible and correctly utilise the
                    Rewards Programme; and
                  </li>

                  <li className="my-1 pt-1 mx-2">
                    the Rewards Programme terms and conditions for customers is
                    displayed in a prominent place at the point of payment in
                    the outlet.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4 last">
                You shall not:
                <ol className="my-1 custom-ol-style">
                  <li className="my-1 pt-1 mx-2 pb-4">
                    permit or condone the use of the Hola Club Cards and Hola
                    Club device for any other purpose other than the Rewards
                    Programme;
                  </li>
                  <li className="my-1 pt-1  mx-2">
                    do anything or act in any manner whatsoever at any time
                    which would or could reasonably have the effect of:
                    <ol className="my-1 mx-2 custom-ol-style">
                      <li className="my-1 pt-1 pb-4">
                        prejudicing, frustrating, limiting, curtailing or
                        preventing Touchsides from giving full and meaningful
                        effect to the Rewards Programme;
                      </li>
                      <li className="my-1 pt-1 pb-4">
                        use any competing point of sale, service and/or product
                        offering;
                      </li>

                      <li className="my-1 pt-1 pb-4">
                        circumventing or excluding Touchsides or diverting any
                        business from Touchsides to a third party; and
                      </li>

                      <li className="my-1 pt-1">
                        prejudicing, frustrating, limiting, curtailing or
                        preventing Touchsides in the proper conduct of its
                        business in any manner whatsoever.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              DISPUTE RESOLUTION
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                Clauses 9.5-11.1 are, collectively, a separate, divisible
                agreement from the rest of the Terms and shall:
                <ol className="custom-ol-style">
                  <li className="my-2 pt-1  mx-2 pb-4">
                    not be or become void, voidable or unenforceable by reason
                    only of any alleged misrepresentation, mistake, duress,
                    undue influence, impossibility (initial or supervening),
                    illegality, immorality, absence of consensus, lack of
                    authority or other cause relating in substance to the rest
                    of the Terms and not to this clause. The Parties intend that
                    all disputes, including the issues set forth above, be and
                    remain subject to arbitration in terms of this clause; and
                  </li>
                  <li className="pt-1 mx-2 pb-4">
                    remain in effect even if the Terms expire or terminate for
                    any reason whatsoever.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                In the event of there being any dispute or difference between
                Touchsides and you arising out of the Terms, whether or not the
                rest of the Terms apart from this clauses 9.5-11.1 are valid
                and enforceable the said dispute shall, may, in addition to the
                remedies available under the CPA, at the discretion of either
                you or Touchsides and on written demand by you or Touchsides be
                submitted to arbitration in Johannesburg in accordance with the
                commercial rules of the Arbitration Foundation of South Africa
                (“AFSA”), which arbitration shall be administered by AFSA.
              </li>
              <li className="my-1 pt-1 pb-4">
                Should AFSA, as an institution, not be operating at that time or
                not be accepting requests for arbitration for any reason, then
                the arbitration shall be conducted in accordance with the AFSA
                rules for commercial arbitration (as last applied by AFSA)
                before an arbitrator appointed by agreement between Touchsides
                and you to the dispute or failing agreement within 10 (ten)
                business days of the demand for arbitration, then Touchsides or
                you shall be entitled to call upon the chairperson of the
                Johannesburg Bar Council to nominate the arbitrator, provided
                that the person so nominated shall be an advocate of not less
                than 10 (ten) years standing as such. The person so nominated
                shall be the duly appointed arbitrator in respect of the
                dispute. In the event of Touchsides and you failing to agree on
                any matter relating to the administration of the arbitration,
                such matter shall be referred to and decided by the arbitrator
                whose decision shall be final and binding on Touchsides and you.
              </li>
              <li className="my-1 pt-1 pb-4">
                Nothing in the Terms shall be deemed to prevent or prohibit
                Touchsides or you from applying to the appropriate court for
                urgent relief or for judgment in relation to a liquidated claim
                or make use of the remedies set out in the CPA.
              </li>
              <li className="my-1 pt-1 pb-4">
                Any arbitration in terms of this clause shall be conducted in
                camera and you shall treat as confidential details of the
                dispute submitted to arbitration, the conduct of the arbitration
                proceedings and the outcome of the arbitration.
              </li>
              <li className="my-1 pt-1 pb-4">
                You irrevocably agree that any decision and awards of the
                arbitrator:
                <ol className="custom-ol-style my-1">
                  <li className="my-1 pt-1 mx-2 pb-4">shall be binding you;</li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    shall be carried into effect; and
                  </li>
                  <li className="my-1 pt-1 mx-2 pb-4">
                    may be made an order of any court of competent jurisdiction.
                  </li>
                </ol>
              </li>
              <li className="my-1 pt-1 pb-4">
                This clause will continue to be binding on Touchsides and you
                despite any termination or cancellation of the Terms.
              </li>
              <li className="my-1 pt-1 pb-4 last">
                Touchsides and you agree that the written demand by Touchsides
                that the dispute or difference be submitted to arbitration, is
                to be deemed to be a legal process for the purpose of
                interrupting extinctive prescription in terms of the
                Prescription Act, 1969.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              GENERAL
            </span>
            <ol className="custom-ol-style">
              <li className="my-1 pt-1 pb-4">
                You are required to comply with all applicable laws, ordinances,
                rules, regulations, orders, licenses, permits, judgments,
                decisions, and other requirements of any governmental authority
                that has jurisdiction over you, including laws that govern
                marketing email (including, but not limited to the Electronic
                Communications and Transactions Act, 2002, CPA and Protection of
                Personal Information Act, 4 of 2013 (“POPI”). In respect of your
                compliance with POPI, you are required to: inform all persons
                whose personal information you process, by way of privacy
                policies and other notices; if consent is required prior to
                processing anyone’s personal information, you are required to
                seek and obtain such prior consent; and in the event that a
                cashier leaves your employment, inform Touchsides to allow
                Touchsides to delete the cashier profiles.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are required to be in possession of a valid liquor license
                at all times, as such you are also responsible for timeously
                renewing your liquor license. Touchsides will not be liable to
                ensure that you have a valid liquor license.
              </li>
              <li className="my-1 pt-1 pb-4">
                To the extent permitted under the CPA, you indemnify Touchsides,
                including its Personnel, affiliates, shareholders, suppliers,
                licensors, product providers, and service providers (the
                “Indemnified Persons”) and hold the Indemnified Persons,
                harmless against all losses, liabilities, costs, expenses,
                fines, penalties, damages, and claims, and all related costs and
                expenses (including legal fees on the scale as between attorney
                and own client, tracing and collection charges, costs of
                investigation, interest and penalties), which you may sustain
                arising out of or in connection with your registration with,
                participation in, or termination of, the Rewards Programme. The
                effect of this clause is that you shall be responsible for the
                listed costs you may suffer and you shall not sue any of the
                Indemnified Persons for such costs which are suffered by you in
                relation to the Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                To the extent permitted under the CPA, you shall defend,
                indemnify, and hold the Indemnified Persons harmless from and
                against any third party suit, proceeding, assertion against any
                damages, judgments, liability, costs and expenses (including
                without limitation any reasonable attorneys’ fees) incurred
                arising from your material breach of the Terms, your
                unauthorised use or misuse of the Rewards Programme or any
                unauthorised combination of the Rewards Programme with any
                hardware, software, products, data or other materials not
                specified or provided by Touchsides. The effect of this clause
                is that you shall be responsible for the listed costs suffered
                by the Indemnified Persons resulting from your breach of the
                Terms your unauthorised use or misuse of the Rewards Programme
                or any unauthorised combination of the Rewards Programme with
                any hardware, software, products, data or other materials not
                specified or provided by Touchsides and you shall not sue any of
                the Indemnified Persons for such costs.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides may implement pricing parameter restrictions on
                specific products for fraud and error control purposes. If you
                feel that a pricing parameter has been incorrectly restricted,
                you may contact us to request a lifting of the restriction.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides is entitled to cede, assign, delegate or in any other
                way dispose or transfer any or all of its rights and obligations
                arising out of the Terms to its successor in title, legal
                cessionary, permitted assign or other third parties, provided
                that such will not be to your detriment.
              </li>
              <li className="my-1 pt-1 pb-4">
                You are not permitted to subcontract, cede, assign or delegate
                any of your rights and/or obligations in terms of the Terms to a
                third party without the prior written consent of Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides provides no guarantee on the availability, accuracy
                or completeness of the content on its documentation and its
                website. The effect of this clause is that Touchsides does not
                promise the content on its documentation and website is accurate
                or complete.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides provides no guarantee that the Hola Club devices, the
                content and the technology of or relating to the Hola Club
                devices is free from errors or omissions. Touchsides cannot
                guarantee that service relating to the Hola Club devices and the
                Reward Programme will be uninterrupted or faultless. You
                specifically agree that Touchsides shall not be responsible for
                non‐authorised access to or alteration of your transmissions or
                data, any material or data sent or received or not sent or
                received, or any transactions entered into through the Rewards
                Programme and/or the Hola Club devices. To the extent permitted
                under the CPA, you indemnify the Indemnified Persons, and hold
                them harmless against all losses, liabilities, costs, expenses,
                fines, penalties, damages, damages and claims, and all related
                costs and expenses (including legal fees on the scale as between
                attorney and own client, tracing and collection charges, costs
                of investigation, interest and penalties), which you may sustain
                as a result in an error, omission, or interruption in the Hola
                Club devices or any service provided by Touchsides in terms of
                the Terms. The effect of this clause is that you shall be
                responsible for the listed costs you may suffer and you shall
                not sue the Indemnified Persons for the listed costs in the
                event such costs arise as a result of any error, omission, or
                interruption in the Hola Club devices or any service provided by
                Touchsides in terms of the Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                Touchsides makes no representations about the suitability,
                reliability, availability, timeliness, and accuracy of the
                services Touchsides provides for any purpose as well as that of
                the Hola Club devices. Touchsides services are provided ‘as is’,
                without warranty of any kind. The effect of this clause is that
                you shall have no claim against Touchsides for any defects in
                the services provided by Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                No advice or information obtained by you from Touchsides or from
                any other person or entity, or through the Rewards Programme,
                content, operational documentation, or Touchsides’ website will
                create any warranty unless contained in the Terms. The effect of
                this clause is that you may not rely on anything said or
                provided in any documentation unless such is contained in the
                Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                You will for the duration of the Terms comply with any
                applicable Touchsides policies and procedure which Touchsides
                notifies you of from time to time.
              </li>
              <li className="my-1 pt-1 pb-4">
                If there is any conflict between any definitions in these Terms
                and Conditions and the ERF and/or the IAF, then, for purposes of
                interpreting any clause of the ERF or the IAF, the definition
                appearing in the ERF or the IAF respectively, shall prevail over
                any other conflicting definition appearing in these Terms and
                Conditions.
              </li>
              <li className="my-1 pt-1 pb-4">
                Entire Agreement: The Terms constitute the entire agreement
                between you and Touchsides and in regard to their subject
                matter. The Terms shall supersede all previous agreements
                entered into between you and Touchsides, both written and oral.
                You waive the right to rely on any alleged provision not
                expressly contained in the Terms.
              </li>
              <li className="my-1 pt-1 pb-4">
                No representations: You agree that no representations, or
                warranties, other than those which are contained in the Terms
                have been made by Touchsides to you, and accordingly only those
                representations and warranties set out in the Terms shall be
                valid and binding as and between you and Touchsides.
              </li>
              <li className="my-1 pt-1 pb-4">
                Governing Law and Jurisdiction: The Terms shall be governed by
                and construed in accordance with the laws of the Republic of
                South Africa without giving effect to any principles of conflict
                of law. You hereby consent to the non-exclusive jurisdiction of
                the High Court of South Africa (Gauteng Local Division,
                Johannesburg) in respect of any disputes arising in connection
                with the services referred to herein, the Terms or any matter
                related to or in connection herewith.
              </li>
              <li className="listiem">
                Payment: All charges are payable in South African Rands free
                from set-off, deduction or bank costs and shall be paid by way
                of electronic fund transfer or cash deposit into the bank
                account nominated by Touchsides from time to time.
              </li>
              <li className="my-1 pt-1 pb-4">
                Costs: Both you and Touchsides will bear their own legal costs
                and disbursements relating to the negotiation, preparation,
                settling, signing, and implementation of the Terms. Any costs,
                including all legal costs on an attorney and own client basis
                and value added tax, incurred by either you or Touchsides
                arising out of or in connection with a breach by you or
                Touchsides shall be borne by the party in breach. The effect of
                this clause is that you shall be responsible for only your won
                costs in relation to preparation of the Terms, however, should
                you be breach the Terms, you will also be responsible for the
                costs of Touchsides incurred by it as a result of your breach.
                The same obligation is imposed on Touchsides for any breach it
                may do.
              </li>
              <li className="my-1 pt-1 pb-4">
                Waiver: Any indulgence that either party to these Terms may
                grant the other or any failure on the part of one party to
                exercise any rights it has in terms of the Terms, will not
                prejudice that party’s rights nor will it be considered a
                novation or waiver of such rights. The effect of this clause is
                that should you be relaxed in or fail to use any of rights under
                the Terms, you will not lose those rights and will still be able
                to enforce such rights. Touchsides also has such a right.
              </li>
              <li className="my-1 pt-1 pb-4">
                Own Risk: To the extent permitted by the CPA, your participation
                in the Rewards Programme is at your own risk and Touchsides make
                no representation or warranties about it. The effect of this
                clause is the limitation of Touchsides’ risk and liability. To
                the extent permitted by the CPA, you may not claim against
                Touchsides for any loss or damage incurred through your
                participation in the Rewards Programme.
              </li>
              <li className="my-1 pt-1 pb-4">
                Save as is expressly provided for in the Terms, no provision of
                the Terms constitutes a stipulation for the benefit of a third
                person which, if accepted by the person, would bind Touchsides
                or you in favour of that person.
              </li>
              <li className="my-1 pt-1 pb-4">
                The terms and conditions of the Terms shall remain in full force
                and effect notwithstanding any substitution of the Hola Club
                devices referred to in the ERF for any other equipment or
                devices by us. The terms and conditions of the Terms shall
                govern the use of such substitute equipment or devices, which
                for the purpose of these Terms will be considered Hola Club
                devices.
              </li>
              <li className="my-1 pt-1 pb-4">
                The expiration or termination of the Terms shall not affect such
                of the provisions of the Terms which are expressly provided to
                operate after any such expiration or termination, or which of
                necessity must continue to have effect after such expiration or
                termination, notwithstanding that the relevant provisions
                themselves do not provide for this.
              </li>
            </ol>
          </li>
          <li>
            <span className="3xl:text-xl md:text-base ml-1 font-bold">
              CHANGE LOG AS AT JULY 2021
            </span>
          </li>
        </ol>
        <ul className="custom-ul-style list-disc">
          <li className="my-1 pt-1">
            <b>
              Scan your purchases and sales, and tap your Hola Club Card and
              Failure to use the Hola Club Device/Card
            </b>
            <li className="my-1 pt-1">
              See clause 5.3 which speaks about the failure to use the device.
            </li>
          </li>
          <li className="my-1 pt-1">
            <b>Rewards and Promotions</b>
            <li className="my-1 pt-1">
              Please refer to clauses 5.3-6.18 for updates on promotions and
              rewards.
            </li>
          </li>
          <li className="my-1 pt-1">
            <b>Data and Privacy</b>
            <li className="my-1 pt-1">
              Outlet is no longer required to keep a copy of the Hola Club
              member terms and conditions or privacy policy.
            </li>
          </li>
          <li className="my-1 pt-1 pb-4">
            <b>General</b>
            <li className="my-1 pt-1">
              Clause 11.2 has been updated to include actions that the outlet
              needs to take with regards to Protection of Personal Information
              Act.
            </li>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OutletOwnerTerms;
