import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Common/Header';
import Footer from './Common/Footer';
import HomePage from './HomePage';
import SignUp from './SignUp/SignUp';
import Contact from './Contact';
import OutletPrivacyPolicy from './PrivacyPolicy/OutletPrivacyPolicy';
import CustomerPrivacyPolicy from './PrivacyPolicy/CustomerPrivacyPolicy';
import CashierPrivacyPolicy from './PrivacyPolicy/CashierPrivacyPolicy';
import CustomerTerms from './TermsAndConditions/CustomerTerms';
import OutletOwnerTerms from './TermsAndConditions/OutletOwnerTerms';
import Competition from './TermsAndConditions/Competition';
import EWallet from './TermsAndConditions/EWallet';
import PopUp from './PopUp';

const Routing = () => {
  const [closePopUp, setClosePopUp] = useState(true);

  return (
    <Router>
      <Header />
      {closePopUp && <PopUp setClosePopUp={setClosePopUp} />}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/contact" component={Contact} />
        <Route
          path="/termsandconditions/customerterms"
          component={CustomerTerms}
        />
        <Route
          path="/termsandconditions/outletownerterms"
          component={OutletOwnerTerms}
        />
           <Route
          path="/termsandconditions/competition"
          component={Competition}
        />
        <Route path="/termsandconditions/ewalletsolution" component={Competition} />
        <Route
          path="/privacypolicy/customerprivacypolicy"
          component={CustomerPrivacyPolicy}
        />
        <Route
          path="/privacypolicy/outletprivacypolicy"
          component={OutletPrivacyPolicy}
        />
          <Route
          path="/privacypolicy/cashierprivacypolicy"
          component={CashierPrivacyPolicy}
        />
        <Route path="/signup" component={SignUp} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routing;
