import React, { useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import { steps } from '../Common/Utilities';
import Input from '../Common/Input';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ErrorMessage from '../Common/ErrorMessage';
import useErrorHandler from '../Common/UseErrorHandler';
import moment from 'moment';
import Loading from '../Common/Loading';

const CardSerial = (props) => {
  const {
    nextStep,
    prevStep,
    validationErrors,
    setValidationErrors,
    cardSerial,
    setCardSerial,
    firstName,
    surname,
    cellNumber,
    dateOfBirth,
    gender,
    isLoading,
  } = props;

  const { hasError, setHasErrors } = useErrorHandler(validationErrors);

  const getGender = (gender) => {
    switch (gender) {
      case 0:
        return 'Unknown';
      case 1:
        return 'Male';
      case 2:
        return 'Female';
      case 3:
        return 'Other';
      default:
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFields = () => {
    setValidationErrors([]);

    //Card serial validation
    if (cardSerial.length !== 16) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Card serial number needs to be 16 digits and must contain numbers only.',
      ]);
      return false;
    }

    if (cardSerialValidation(cardSerial)) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Card serial must contain numbers only.',
      ]);
      return false;
    }
    nextStep({ cardNumber: cardSerial });
    return true;
  };

  const cardSerialValidation = (num) => {
    var ex = new RegExp('^[0-9]+$');
    if (ex.test(num)) return false;
    return true;
  };

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center"
        onSubmit={(e) => {
          e.preventDefault();
          validateFields();
        }}
      >
        <ArrowBackIosRoundedIcon
          className="mb-4 text-primary"
          onClick={prevStep}
        />

        <p className={steps}>Step 6 of 7</p>
        <div className="flex flex-col mb-8">
          <p className="text-left mb-2">Please confirm your details:</p>

          <div className="flex flex-row my-1">
            <p className="font-bold text-left">First Name:</p>
            <p className="ml-4">{firstName}</p>
          </div>
          <div className="flex flex-row my-1">
            <p className="font-bold text-left">Last Name:</p>
            <p className="ml-4">{surname}</p>
          </div>
          <div className="flex flex-row my-1">
            <p className="font-bold text-left">Cell Number:</p>
            <p className="ml-4">{cellNumber}</p>
          </div>
          <div className="flex flex-row my-1">
            <p className="font-bold text-left">Date of Birth:</p>
            <p className="ml-4">{moment(dateOfBirth).format('DD-MM-YYYY')}</p>
          </div>
          <div className="flex flex-row my-1">
            <p className="font-bold text-left">Gender:</p>
            <p className="ml-4">{getGender(gender)}</p>
          </div>
        </div>
        <div className="w-full">
          <p className="text-left">
            * Please enter your 16-digit Hola Club card no:
          </p>
          <Input
            name="cardNumber"
            value={cardSerial}
            onChange={(e) => setCardSerial(e.target.value)}
          />
        </div>
        <label className="text-xs text-left mb-4">* required</label>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {hasError && <ErrorMessage error content={setHasErrors} />}
          </Fragment>
        )}
        <Button type="submit" content="NEXT" />
      </form>
    </Fragment>
  );
};

export default CardSerial;
