import React, { useState, useEffect, Fragment } from 'react';
import Button from '../Common/Button';
import { steps } from '../Common/Utilities';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import DatePicker from 'react-datepicker';
import ErrorMessage from '../Common/ErrorMessage';
import useErrorHandler from '../Common/UseErrorHandler';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Loading from '../Common/Loading';
import subDays from 'date-fns/subDays';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';

const DateOfBirth = (props) => {
  const {
    nextStep,
    prevStep,
    setGender,
    gender,
    validationErrors,
    setValidationErrors,
    dateOfBirth,
    setDateOfBirth,
    isLoading,
    returnToStart,
  } = props;

  const { hasError, setHasErrors } = useErrorHandler(validationErrors);
  const [endProcess, setEndProcess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFields = () => {
    setValidationErrors([]);

    // Gender validation
    if (gender === null) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please select a gender.',
      ]);
      return false;
    }

    // Date of birth validation
    if (dateOfBirth === null) {
      setValidationErrors((prevState) => [
        ...prevState,
        'Please select your date of birth.',
      ]);
      return false;
    }
    if (dateOfBirth > subDays(new Date(), 6570)) {
      setEndProcess(true);
      return false;
    }
    nextStep({
      dateOfBirth: moment(
        dateOfBirth.setTime(
          dateOfBirth.getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
      ),
      gender: gender,
    });
    return true;
  };

  return (
    <Fragment>
      <form
        className="flex flex-col mx-auto self-center"
        onSubmit={(e) => {
          e.preventDefault();
          validateFields();
        }}
      >
        {!endProcess ? (
          <Fragment>
            <ArrowBackIosRoundedIcon
              className="mb-4 text-primary"
              onClick={prevStep}
            />
            <p className={steps}>Step 4 of 7</p>
            <Fragment>
              <p className="text-left mb-4">* Date of Birth:</p>
              <DatePicker
                className="text-center border border-primary rounded-md p-2 w-full"
                selected={dateOfBirth === undefined ? null : dateOfBirth}
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
                placeholderText="DD/MM/YYYY"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                dateFormat="dd/MM/yyyy"
              />
            </Fragment>
            <div className="flex flex-col mb-4 mt-6">
              <p className="text-left my-2">* Gender:</p>
              <div className="flex flex-row my-1">
                <input
                  checked={gender === 1}
                  type="radio"
                  className="mt-1.4"
                  name="gender"
                  value="Male"
                  onChange={(e) => {
                    setGender(1);
                  }}
                />
                <p className="text-left ml-4">Male</p>
              </div>
              <div className="flex flex-row my-1">
                <input
                  checked={gender === 2}
                  type="radio"
                  className="mt-1.4"
                  name="gender"
                  value="Female"
                  onChange={(e) => {
                    setGender(2);
                  }}
                />
                <p className="text-left ml-4">Female</p>
              </div>
              <div className="flex flex-row my-1">
                <input
                  checked={gender === 3}
                  type="radio"
                  className="mt-1.4"
                  name="gender"
                  value="Other"
                  onChange={(e) => {
                    setGender(3);
                  }}
                />
                <p className="text-left ml-4">Other</p>
              </div>
              <label className="text-xs text-left my-6">* required</label>
              {isLoading ? (
                <Loading />
              ) : (
                <Fragment>
                  {hasError && <ErrorMessage error content={setHasErrors} />}
                </Fragment>
              )}
            </div>
            <Button content="NEXT" type="submit" />
          </Fragment>
        ) : (
          <Fragment>
            <div className="border-primary border rounded-md mb-8 mt-6">
              <SentimentDissatisfiedRoundedIcon
                className="w-8 self-center text-center -mt-4 bg-lightgreen rounded-full p-1 border-primary border"
                style={{ zoom: 1.5 }}
              />
              <div className="flex flex-row text-center mb-2 ">
                <div className="text-center px-4 py-12 flex-1">
                  Sorry, you need to be 18 years or older to be part of Hola
                  Club.
                </div>
              </div>
            </div>
            <button
              className="underline text-primary mb-6"
              onClick={returnToStart}
            >
              end
            </button>
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};

export default DateOfBirth;
