import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const PopUp = (props) => {
  const { setClosePopUp } = props;
  return (
    <div>
      <div className="flex flex-row">
        <div className="hover:shadow-lg bg-gray border-2 border-primary rounded-md py-4 flex flex-row text-sm mx-4 fixed bottom-0 z-50 mb-16 items-center">
          <div className="w-11/12 md:w-full sm:w-full xs:w-full px-4">
            <div className="w-full mb-2 justify-end 3xl:hidden 3xl:hidden md:flex">
              <button
                className="bg-white rounded-md p-2 hover:bg-mediumgray hover:text-white"
                onClick={() => setClosePopUp(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <p className="mx-auto flex-1 text-black pb-2 ">
              <b>Updates to our Terms and Conditions and Privacy Policy: </b>
              We have updated our terms and conditions and privacy policy.
              Please refer to the “change log” at the bottom of the privacy
              policy and terms and conditions to learn more about these changes.
              To view, visit{' '}
              <a
                className="text-primary underline"
                href="https://www.holaclub.co.za"
              >
                www.holaclub.co.za
              </a>{' '}
              and click on the "terms & conditions" and "privacy policy" links
              at the top right of the page.{' '}
            </p>
            <p className="mx-auto flex-1 text-black ">
              By using this website, you agree to the Website {''}
              <a
                className="text-primary underline"
                href="https://www.touchsides.com/terms-conditions.html"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions{''}
              </a>
              {''} and{' '}
              <a
                className="text-primary underline"
                href="https://www.touchsides.com/cookie-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy{''}
              </a>{' '}
              and you acknowledge that they apply to you.
            </p>
          </div>
          <div className="w-1/12 text-center items-center md:hidden sm:hidden xs:hidden">
            <button
              className="bg-white rounded-md p-2 hover:bg-mediumgray hover:text-white"
              onClick={() => setClosePopUp(false)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
