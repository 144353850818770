import React from "react";

const Competition = () => {
  return (
    <div className="flex flex-col self-center mx-auto justify-center mt-12 ">
      <div className="self-center w-1/2 mx-auto justify-center md:w-9/12">
        <h1 className="text-primary text-2xl font-bold 3xl:pb-24 pt-24 md:pb-12 text-center">
          Competition Terms and Conditions
        </h1>
        <ol className="text-base pb-4 list-decimal">
          <li className="text-base pt-1 pb-4">
            Promoter: The promoter to this competition is Touchsides SA
            Proprietary Limited (registration number: 2007/007727/07), situated
            at the Oval West Wing, Wanderers Office Park, 52 Corlett Drive,
            Illovo, 2196 <b>(“Touchsides”)</b>.
          </li>
          <li className="text-base pt-1 pb-4">
            Win your share of R10, 000.00 (Ten Thousand Rands) and your share of
            2x tickets to a tour of the HEINEKEN SA Brewery in Sedibeng
            (“Sedibeng”) when you follow our Hola Club Facebook Page, like the
            competition post, and tag 3 (three) of your friends in the comments
            section of the competition post (this/the <b>“Promotion”</b>).{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            The Promotion will run from <b>21 NOVEMBER 2022</b> and close on{" "}
            <b>24 NOVEMBER 2022</b>. The prize will be procured after the end of
            the competition on <b>25 NOVEMBER 2022(“Promotion Period”).</b>{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            The Promotion is open to all legal residents of the Republic of
            South Africa <b>excluding</b>:
          </li>
          <ul className="text-base  pt-1 pb-4 ml-12 list-lowerAlpha ">
            <li className="text-base  pt-1 pb-4">
              {" "}
              The directors, members, partners, employees or agents of, or
              consultants of Touchsides or any other person who directly or
              indirectly controls or is controlled by Touchsides or a supplier
              of goods or services in connection with this Promotion;{" "}
            </li>
            <li className="text-base  pt-1 pb-4 ">
              {" "}
              The relatives, family members, spouses, life partners or business
              partners of any of the persons specified in paragraph (a) above;{" "}
            </li>
            <li className="text-base  pt-1 pb-4 ">
              {" "}
              Persons under the age of 21 years and over the age of 35 years;{" "}
            </li>
            <li className="text-base  pt-1 pb-4 ">
              {" "}
              Persons who are not legal residents of the Republic of South
              Africa; or
            </li>
            <li className="text-base  pt-1 pb-4 ">
              {" "}
              Persons who are not residents of the city of Johannesburg.
            </li>
          </ul>
          <li className="text-base pt-1 pb-4">
            All participants must be between the ages of 21 and 35 years at the
            date of entry and will be required to exhibit valid identity
            documents as proof thereof.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Your participation in this Promotion constitutes your acceptance of
            these terms and conditions.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            To enter the Promotion a participant must:{" "}
          </li>
          <ul className="text-base  pt-1 pb-4  ml-12 list-lowerAlpha ">
            <li className="text-base pt-1 pb-4">
              follow the Hola Club Facebook page, like the competition post, and
              tag 3 (three) friends on the competition post comment section;{" "}
            </li>
            <li className="text-base pt-1 pb-4">
              Be willing to participate in the Hola Club video shoot that will
              be communicated to the winners;;{" "}
            </li>
            <li className="text-base pt-1 pb-4">
              Be from and are residents of Johannesburg; and;{" "}
            </li>
            <li className="text-base pt-1 pb-4">
              The entrants will be automatically entered into the competition.;{" "}
            </li>
          </ul>
          <li className="text-base pt-1 pb-4">
            The prize for this Promotion is a share of R10, 000.00 (Ten Thousand
            Rands) and a share of 2x tickets to a tour of Sedibeng. The Prize
            includes a shuttle service to a Hola Club video shoot to be arranged
            by the Promoter and a shuttle service to Sedibeng. There will be a
            total of 2 winners.
          </li>
          <li className="text-base pt-1 pb-4">
            Once the competition has closed (as set out in paragraph 3 above), 2
            (two) winners will be chosen at random.
          </li>
          <li className="text-base pt-1 pb-4">
            The winner will forfeit their prize in the event that he/she fails
            to comply with Touchsides’ requirements within the time provided to
            you by Touchsides in order to process the prize.
          </li>
          <li className="text-base pt-1 pb-4">
            Touchsides reserve the right to substitute the prize mentioned above
            with any other gift of comparable commercial value.
          </li>
          <li className="text-base pt-1 pb-4">
            It is the winner’s responsibility to ensure that they are in
            possession of all the relevant documents, including but not limited
            to a valid identity document. The winner shall, within 48 hours of
            request by Touchsides or agent acting on behalf of Touchsides,
            provide and send proof of the following documents to Touchsides (if
            these documents are not provided within the 48 hours of request, the
            prize will immediately be forfeited to Touchsides for a
            reselection):{" "}
          </li>
          <ul className="text-base  pt-1 pb-4  ml-12 list-lowerAlpha">
            <li li className="text-base  pt-1 pb-4  ">
              ) a valid identity document of the winner or a Certified copy
              (certification stamp should be less than 3 months old) of valid
              identity document of the winner;{" "}
            </li>
            <li className="text-base  pt-1 pb-4">
              ) confirmation of a bank account; and{" "}
            </li>
            <li className="text-base  pt-1 pb-4">
              ) any such other proof and/or documents that may be reasonably
              required by Touchsides.
            </li>
          </ul>
          <li className="text-base pt-1 pb-4">
            The decision on the winner (s) is final and binding and no
            correspondence will be entered into. Every effort will be made to
            ensure that the prize is as described above, but due to the nature
            of the prize, it may be altered at any time, at Touchsides’s
            discretion.
          </li>
          <li className="text-base pt-1 pb-4">
            Although we have taken care to make sure that the prize is of a good
            quality, we do not give any warranties about the prize and you agree
            to accept the prize “as is”. Subject to applicable law, you also
            indemnify Touchsides against any damages that anyone may suffer as a
            result of the prize/s or this Promotion, including consequential and
            economic loss.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Winners will be contacted via the Facebook direct messaging by 25
            November 2022 (this date is subject to change without notice).
            Touchsides reserves the right to disqualify a winner if he/she does
            not respond to the direct message within 24 to 48 hours of the
            winner selection and randomly select a replacement winner from the
            competition entries.
          </li>
          <li className="text-base pt-1 pb-4">
            It is your responsibility to ensure that the Facebook profile used
            to enter this Promotion is yours and accessible to you at all times.
          </li>
          <li className="text-base pt-1 pb-4">
            Any costs or expenses incurred in respect of items not specifically
            included in the prize are for the winner’s own account. Touchsides,
            and its affiliates (if applicable), will not be responsible for any
            other expenses which the winner may incur as a result of their
            acceptance and/or use of a prize, whether foreseen or not.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Subject to clause 11 above, the prize is not transferrable and may
            not be deferred or changed for any other item.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            To the extent that any taxes, duties, levies or other charges may be
            levied on a prize by the State or any other competent government or
            regulatory body, the winner/s will be liable therefore, and
            Touchsides will not increase the value of the prize/s to compensate
            for such charges.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Touchsides reserves the right to terminate or temporarily suspend or
            amend this Promotion including these terms and conditions at any
            time during the Promotion Period for any reason or if it experiences
            difficulties that might compromise its integrity.
          </li>
          <li className="text-base pt-1 pb-4">
            Neither Touchsides nor their respective advertising, media and PR
            agencies, nor their associated companies and affiliates, nor their
            trading partners shall be liable in any way whatsoever for any loss,
            damage or costs, howsoever arising, suffered by a participant of
            this Promotion and/or receipt and/or use of the prize offered.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Under no circumstances shall Touchsides nor their respective
            advertising, media and PR agencies, nor their associated companies
            and affiliates, nor their trading partners be liable to anyone who
            enters this Promotion for any indirect or consequential loss
            howsoever arising which may be suffered in relation to this
            Promotion, whether as a result of any negligent act or omission or
            any default on the part of Touchsides or otherwise.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            By participating in this Promotion, each participant hereby consents
            to the processing of his or her personal information by or on behalf
            of Touchsides. All personal information of the participant will be
            processed in accordance with the applicable data protection
            legislation including the Protection of Personal Information Act,
            2013. To see our Privacy Policy, please visit the www.holaclub.co.za
            website.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            Touchsides may use your personal information for the purposes of
            sending you marketing communications. This will be subject to
            applicable law and Hola Club’s Privacy Policy. Touchsides will
            obtain your consent before it sends any such communications to you.
          </li>
          <li className="text-base pt-1 pb-4">
            The winners will be requested to take part in featuring in the Hola
            Club video shoot in respect of the prize and participation is not
            conditional upon the winner’s consent to take part in such publicity
            and having the opportunity to decline such request. Touchsides shall
            contact each winner to arrange for the video shoot and a shuttle to
            the venue.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            It will be the winner’s responsibility to transport themselves to
            the collection point which shall be communicated by Touchsides once
            the date for the video shoot has been confirmed. The winner will not
            be entitled to any payment or other remuneration for participating
            in the video shoot or otherwise. All and any materials, including
            publicity materials taken on the day, will be the sole property of
            Touchsides.{" "}
          </li>
          <li className="text-base pt-1 pb-4">
            The laws of the Republic of South Africa govern these Terms and
            Conditions and participants agree to be subject to the exclusive
            jurisdiction of the South African courts.
          </li>
          <li className="text-base pt-1 pb-4">
            A copy of these terms and conditions are available and can be
            downloaded from www.holaclub.com. For all queries in respect of this
            Promotion, please contact Touchsides on 0800 014 809.{" "}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Competition;
